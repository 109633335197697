import React, { useContext, useState, useEffect, useRef } from "react";
import { Can } from "../Can";
import { useHistory } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import usePlans from "../../hooks/usePlans";
import { AuthContext } from "../../context/Auth/AuthContext";
import { TicketsContext } from "../../context/Tickets/TicketsContext";
import useSettings from "../../hooks/useSettings";
import ConfirmationModal from "../ConfirmationModal";
import TransferTicketModal from "../TransferTicketModal";
import ScheduleModal from "../ScheduleModal";
import { ButtonGroup, IconButton, Tooltip, MenuItem} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TicketOptionsMenu from "../TicketOptionsMenu";
import {
  Replay,
  ArrowDropDown as ArrowDropDownIcon,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  actionButtons: {
    marginRight: 6,
    flex: "none",
    alignSelf: "center",
    marginLeft: "auto",
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  [theme.breakpoints.down("sm")]: {
    position: "absolute",
    right: 0,
  },    
  },
  bottomButtonVisibilityIcon: {
    padding: 1,
  },
  buttons: {
    display: "flex",
    padding: "15px",
  },
  button: {
    flexGrow: 1,
    margin: theme.spacing(1),
  },
}));

const SessionSchema = Yup.object().shape({
  ratingId: Yup.string().required(i18n.t("required")),
});

const TicketActionButtons = ({ ticket, userName }) => {
  const classes = useStyles();
  const history = useHistory();
  const isMounted = useRef(true);
  const [loading, setLoading] = useState(false);
  const [ratings, setRatings] = useState(false);
  const { user } = useContext(AuthContext);
  const { setCurrentTicket } = useContext(TicketsContext);
  const [initialState, setInitialState] = useState({ ratingId: "" });
  const [dataRating, setDataRating] = useState([]);
  const [open, setOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [transferTicketModalOpen, setTransferTicketModalOpen] = useState(false);
  const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
  const [contactId, setContactId] = useState(null);
  const [acceptAudioMessage, setAcceptAudio] = useState(
    ticket.contact.acceptAudioMessage
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const ticketOptionsMenuOpen = Boolean(anchorEl);
  const handleCloseTicketOptionsMenu = e => {
      setAnchorEl(null);
  };
  
  const handleOpenTicketOptionsMenu = e => {
    setAnchorEl(e.currentTarget);
  };
  
  const [showSchedules, setShowSchedules] = useState(false);

  const { getAll: getAllSettings } = useSettings();
  const { getPlanCompany } = usePlans();

  useEffect(() => {
    const fetchData = async () => {
      const settingList = await getAllSettings();
      const setting = settingList.find((setting) => setting.key === "userRating");
      if (setting.value === "enabled") {
        setRatings(true);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const companyId = localStorage.getItem("companyId");
      const planConfigs = await getPlanCompany(undefined, companyId);
      setShowSchedules(planConfigs.plan.useSchedules);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const loadRatings = async () => {
      try {
        const { data } = await api.get(`/ratings/list`);
        setDataRating(data);
      } catch (err) {
        toastError(err);
      }
    };
    if (open && ratings) {
      loadRatings();
    }
  }, [open, ratings]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  const handleCloseTicketWithoutFarewellMsg = async () => {
    setLoading(true);
    try {
      await api.put(`/tickets/${ticket.id}`, {
        status: "closed",
        userId: user?.id || null,
        sendFarewellMessage: false,
        amountUsedBotQueues: 0,
      });

      setLoading(false);
      history.push("/tickets");
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
  };
  
  const handleCloseTransferTicketModal = () => {
    if (isMounted.current) {
      setTransferTicketModalOpen(false);
    }
  };

  const handleDeleteTicket = async () => {
    try {
      await api.delete(`/tickets/${ticket.id}`);
    } catch (err) {
      toastError(err);
    }
  };

  const handleUpdateTicketStatus = async (e, status, userId, queueId) => {
    e.preventDefault();
    setLoading(true);
    try {
      await api.put(`/tickets/${ticket.id}`, {
        status: status,
        userId: userId || null,
        queueId: queueId || null,
      });

      setLoading(false);
      if (status === "open") {
        setCurrentTicket({ ...ticket, code: "#open" });
      } else {
        setCurrentTicket({ id: null, code: null });
        history.push("/tickets");
      }
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
  };

  const handleSendRating = async (userId, ratingId) => {
    handleClose();
    setLoading(true);
    try {
      await api.post(`/ratings/messages/${ticket.id}`, {
        userId: userId || null,
        ratingId,
      });

      setLoading(false);
      history.push("/tickets");
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
  };

  return (
    <>
      <div className={classes.actionButtons}>
        {ticket.status === "closed" && (
          <Button
            loading={loading}
            size="small"
            variant="contained"
            color="primary"            
            onClick={(e) => handleUpdateTicketStatus(e, "open", user?.id)}
          >
            {i18n.t("messagesList.header.buttons.reopen")}
          </Button>
        )}
        {ticket.status === "open" && (
          <>
          
        <ButtonGroup size="small" variant="contained" color="primary">
          <Button
//            onClick={e => handleUpdateTicketStatus(e, "closed", user?.id)}
            onClick={handleClickOpen}
          >
            {i18n.t("messagesList.header.buttons.resolve")}
          </Button>

          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={handleOpenTicketOptionsMenu}
            style={{ borderRadius: '0 4px 4px 0', border: 'none' }}
          >
            <ArrowDropDownIcon />
          </Button>


          <TicketOptionsMenu
            ticket={ticket}
            anchorEl={anchorEl}
            menuOpen={ticketOptionsMenuOpen}
            handleClose={handleCloseTicketOptionsMenu}
          />
        </ButtonGroup>
          </>
        )}
        {ticket.status === "pending" && (
          <Button
          loading={loading}
            size="small"
            variant="contained"
            color="primary"
            onClick={(e) => handleUpdateTicketStatus(e, "open", user?.id)}
          >
            {i18n.t("messagesList.header.buttons.accept")}
          </Button>
        )}
      </div>
      <Formik
        initialValues={initialState}
        enableReinitialize={true}
        validationSchema={SessionSchema}
        onSubmit={(values) => handleSendRating(user?.id, values.ratingId)}
      >
        {({ values, touched, errors, isSubmitting, setFieldValue }) => (
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <Form>
              <DialogTitle id="alert-dialog-title">
                {ratings
                  ? i18n.t("messagesList.header.dialogActions.ratingTitle")
                  : i18n.t("messagesList.header.dialogActions.closingTitle")}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {ratings && (
                    <div style={{ marginTop: 8 }}>
                      <Autocomplete
                        size="small"
                        options={dataRating}
                        name="ratingId"
                        getOptionLabel={(option) => option.name}
                        onChange={(e, value) =>
                          setFieldValue("ratingId", value?.id || "")
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={touched.ratingId && Boolean(errors.ratingId)}
                            helperText={touched.ratingId && errors.ratingId}
                            variant="outlined"
                            placeholder={i18n.t("messagesList.header.dialogActions.selectRatingTitle")}
                          />
                        )}
                      />
                    </div>
                  )}
                </DialogContentText>
              </DialogContent>
              <DialogActions className={classes.buttons}>
                <Button
                  onClick={handleCloseTicketWithoutFarewellMsg}
                  variant="contained"
                  color= "primary"
                  className={classes.button}
                >
                  {i18n.t("messagesList.header.dialogActions.withoutFarewellMsg")}
                </Button>
                <Button
                  onClick={(e) => handleUpdateTicketStatus(e, "closed", user?.id, ticket?.queue?.id)}
                  variant="contained"
                  color= "primary"
                  className={classes.button}
                >
                  {i18n.t("messagesList.header.dialogActions.withFarewellMsg")}
                </Button>
                {ratings && (
                  <Button
                    disabled={isSubmitting}
                    variant="contained"
                    type="submit"
                    color= "primary"
                    className={classes.button}
                  >
                    {i18n.t("messagesList.header.dialogActions.withRatingSuccess")}
                  </Button>
                )}
              </DialogActions>
            </Form>
          </Dialog>
        )}
      </Formik>
    </>
  );
};

export default TicketActionButtons;