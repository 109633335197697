import React, { useState, useEffect, useContext, useRef } from "react";
import withWidth from "@material-ui/core/withWidth";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";
import MicRecorder from "mic-recorder-to-mp3";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import IconButton from "@material-ui/core/IconButton";
import MoreVert from "@material-ui/icons/MoreVert";
import MoodIcon from "@material-ui/icons/Mood";
import SendIcon from "@material-ui/icons/Send";
import CancelIcon from "@material-ui/icons/Cancel";
import ClearIcon from "@material-ui/icons/Clear";
import MicIcon from "@material-ui/icons/Mic";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { FormControlLabel, Hidden, Menu, MenuItem, Switch } from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { isString, isEmpty, isObject, has } from "lodash";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import RecordingTimer from "./RecordingTimer";
import { ReplyMessageContext } from "../../context/ReplyingMessage/ReplyingMessageContext";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";

import useQuickMessages from "../../hooks/useQuickMessages";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    background: theme.palette.tabHeaderBackground,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderLeft: 0,
    borderRight: 0,
    borderBottom: 0,
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: 0,
      width: "100%",
    },
  },

  newMessageBox: {
    background: theme.palette.tabHeaderBackground,
    width: "100%",
    display: "flex",
    padding: "7px",
    alignItems: "center",
  },

  messageInputWrapper: {
    border: `1px solid ${theme.palette.inputWrapperBorder}`,
    padding: 6,
    marginRight: 7,
    background: theme.palette.background.paper,
    display: "flex",
    borderRadius: 7.5,
    flex: 1,
    position: "relative",
  },

  messageInput: {
    paddingLeft: 10,
    flex: 1,
    border: "none",
    background: theme.palette.background.paper,
  },

  sendMessageIcons: {
    color: theme.palette.messageIcons,
  },

  sendMessageIcons2: {
    color: theme.palette.primary.main,
  },

  sendMessageIcons3: {
    color: "red",
  },

  uploadInput: {
    display: "none",
  },

  emojiBox: {
    left: 3.5,
    position: "absolute",
    width: "65%",
    bottom: 62,
    [theme.breakpoints.down("sm")]: {
      left: 1.5,
      bottom: 58,
      width: "99%",
    },
  },

  circleLoading: {
    color: green[500],
    opacity: "70%",
    position: "absolute",
    top: "20%",
    left: "50%",
    marginLeft: -12,
  },

  audioLoading: {
    color: green[500],
    opacity: "70%",
  },

  recorderWrapper: {
    display: "flex",
    alignItems: "center",
    alignContent: "middle",
  },

  cancelAudioIcon: {
    color: "red",
  },

  sendAudioIcon: {
    color: "green",
  },

  replyginMsgWrapper: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 8,
    paddingLeft: 73,
    paddingRight: 7,
  },

  replyginMsgContainer: {
    flex: 1,
    marginRight: 5,
    overflowY: "hidden",
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
  },

  replyginMsgBody: {
    padding: 10,
    height: "auto",
    display: "block",
    whiteSpace: "pre-wrap",
    overflow: "hidden",
  },

  replyginContactMsgSideColor: {
    flex: "none",
    width: "4px",
    backgroundColor: "#35cd96",
  },

  replyginSelfMsgSideColor: {
    flex: "none",
    width: "4px",
    backgroundColor: "#6bcbef",
  },

  messageContactName: {
    display: "flex",
    color: "#4AB9FB",
    fontWeight: 500,
  },

  messageFromMe: {
    display: "flex",
    color: "#2AB783",
    fontWeight: 500,
  },

  attachedFileInfo: {
    width: "100%",
    display: "flex",
    padding: "5px",
    alignItems: "center",
    background: theme.palette.background.paper,
    justifyContent: "center",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },

  previewImage: {
    maxHeight: "100px",
    marginRight: "10px",
  },

  previewVideo: {
    maxHeight: "100px",
    marginRight: "10px",
  },

  previewAudio: {
    marginRight: "10px",
  },
}));

const customTranslations = {
  search: i18n.t("emojiPicker.search"),
  categories: {
    search: i18n.t("emojiPicker.categories.search"),
    recent: i18n.t("emojiPicker.categories.recent"),
    smileys: i18n.t("emojiPicker.categories.smileys"),
    people: i18n.t("emojiPicker.categories.people"),
    nature: i18n.t("emojiPicker.categories.nature"),
    foods: i18n.t("emojiPicker.categories.foods"),
    activity: i18n.t("emojiPicker.categories.activity"),
    places: i18n.t("emojiPicker.categories.places"),
    objects: i18n.t("emojiPicker.categories.objects"),
    symbols: i18n.t("emojiPicker.categories.symbols"),
    flags: i18n.t("emojiPicker.categories.flags"),
  },
  notfound: i18n.t("emojiPicker.notfound"),
};

const FileInput = (props) => {
  const { handleChangeMedias, disableOption, medias, setMedias } = props;
  const classes = useStyles();
  const fileInputRef = useRef();

  const handleFileChange = (e) => {
    handleChangeMedias(e);
    // Reset the value of the file input to allow re-uploading the same file
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <>
      <input
        multiple
        type="file"
        id="upload-button"
        disabled={disableOption()}
        className={classes.uploadInput}
        onChange={handleFileChange}
        ref={fileInputRef}
      />
      <label htmlFor="upload-button">
        <IconButton
          aria-label="upload"
          component="span"
          disabled={disableOption()}
        >
          <AttachFileIcon className={classes.sendMessageIcons} />
        </IconButton>
      </label>
    </>
  );
};


const ActionButtons = (props) => {
  const {
    inputMessage,
    medias,
    loading,
    recording,
    ticketStatus,
    handleSendMessage,
    handleCancelAudio,
    handleUploadAudio,
    handleStartRecording,
  } = props;
  const classes = useStyles();

  if (inputMessage || medias.length > 0) {
    return (
      <IconButton
        aria-label="sendMessage"
        component="span"
        onClick={handleSendMessage}
        disabled={loading}
      >
        <SendIcon className={classes.sendMessageIcons2} />
      </IconButton>
    );
  } else if (recording) {
    return (
      <div className={classes.recorderWrapper}>
        <IconButton
          aria-label="cancelRecording"
          component="span"
          fontSize="large"
          disabled={loading}
          onClick={handleCancelAudio}
        >
          <HighlightOffIcon className={classes.cancelAudioIcon} />
        </IconButton>
        {loading ? (
          <div>
            <CircularProgress className={classes.audioLoading} />
          </div>
        ) : (
          <RecordingTimer />
        )}
        <IconButton
          aria-label="sendRecordedAudio"
          component="span"
          onClick={handleUploadAudio}
          disabled={loading}
        >
          <CheckCircleOutlineIcon className={classes.sendAudioIcon} />
        </IconButton>
      </div>
    );
  } else {
    return (
      <IconButton
        aria-label="showRecorder"
        component="span"
        disabled={loading || ticketStatus !== "open"}
        onClick={handleStartRecording}
      >
        <MicIcon className={classes.sendMessageIcons} />
      </IconButton>
    );
  }
};

const CustomInput = (props) => {
  const {
    loading,
    inputRef,
    ticketStatus,
    inputMessage,
    setInputMessage,
    handleSendMessage,
    handleInputPaste,
    disableOption,
  } = props;
  const classes = useStyles();
  const [quickMessages, setQuickMessages] = useState([]);
  const [options, setOptions] = useState([]);
  const [popupOpen, setPopupOpen] = useState(false);

  const { user } = useContext(AuthContext);

  const { list: listQuickMessages } = useQuickMessages();

  useEffect(() => {
    async function fetchData() {
      const companyId = localStorage.getItem("companyId");
      const messages = await listQuickMessages({ companyId, userId: user.id });
      const options = messages.map((m) => {
        let truncatedMessage = m.message;
        if (isString(truncatedMessage) && truncatedMessage.length > 35) {
          truncatedMessage = m.message.substring(0, 35) + "...";
        }
        return {
          value: m.message,
          label: `${m.shortcode} - ${truncatedMessage}`,
        };
      });
      setQuickMessages(options);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      isString(inputMessage) &&
      !isEmpty(inputMessage) &&
      inputMessage.length > 0
    ) {
      const firstWord = inputMessage.charAt(0);
      setPopupOpen(firstWord.indexOf("/") > -1);

      const searchString = inputMessage.startsWith("/")
        ? inputMessage.slice(1).toLowerCase()
        : inputMessage.toLowerCase();

      const filteredOptions = quickMessages.filter((m) =>
        m.label.toLowerCase().includes(searchString)
      );
      setOptions(filteredOptions);
    } else {
      setPopupOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputMessage]);

  const onKeyPress = (e) => {
    if (loading || e.shiftKey) return;
    else if (e.key === "Enter") {
      handleSendMessage();
    }
  };

  const onPaste = (e) => {
    if (ticketStatus === "open") {
      handleInputPaste(e);
    }
  };

  const renderPlaceholder = () => {
    if (ticketStatus === "open") {
      return i18n.t("messagesInput.placeholderOpen");
    }
    return i18n.t("messagesInput.placeholderClosed");
  };

  const setInputRef = (input) => {
    if (input) {
      input.focus();
      inputRef.current = input;
    }
  };

  return (
    <div className={classes.messageInputWrapper}>
      <Autocomplete
        freeSolo
        open={popupOpen}
        id="grouped-demo"
        value={inputMessage}
        options={options}
        closeIcon={null}
        getOptionLabel={(option) => {
          if (isObject(option)) {
            return option.label;
          } else {
            return option;
          }
        }}
        onChange={(event, opt) => {
          if (isObject(opt) && has(opt, "value")) {
            setInputMessage(opt.value);
            setTimeout(() => {
              inputRef.current.scrollTop = inputRef.current.scrollHeight;
            }, 200);
          }
        }}
        onInputChange={(event, opt, reason) => {
          if (reason === "input") {
            setInputMessage(event.target.value);
          }
        }}
        onPaste={onPaste}
        onKeyPress={onKeyPress}
        style={{ width: "100%" }}
        renderInput={(params) => {
          const { InputLabelProps, InputProps, ...rest } = params;
          return (
            <InputBase
              {...params.InputProps}
              {...rest}
              disabled={disableOption()}
              inputRef={setInputRef}
              placeholder={renderPlaceholder()}
              multiline
              className={classes.messageInput}
              maxRows={10}
              readOnly={ticketStatus === "closed"}
              onKeyPress={(e) => {
                if (!e.shiftKey && e.key === "Enter") {
                  e.preventDefault();
                }
              }}
            />
          );
        }}
      />
    </div>
  );
};

const MessageInput = (props) => {
  const { ticketStatus, ticketId } = props;
  const classes = useStyles();

  const [medias, setMedias] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [showEmoji, setShowEmoji] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recording, setRecording] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const inputRef = useRef();
  const { setReplyingMessage, replyingMessage } =
    useContext(ReplyMessageContext);
  const { user } = useContext(AuthContext);
  const [signMessage, setSignMessage] = useState();

  useEffect(() => {
    inputRef.current.focus();
  }, [replyingMessage]);

  useEffect(() => {
    inputRef.current.focus();
    return () => {
      setInputMessage("");
      setShowEmoji(false);
      setMedias([]);
      setReplyingMessage(null);
    };
  }, [ticketId, setReplyingMessage]);

  const handleAddEmoji = (e) => {
    let emoji = e.native;
    setInputMessage((prevState) => prevState + emoji);
  };

  const handleChangeMedias = (e) => {
    if (!e.target.files) {
      return;
    }

    const selectedMedias = Array.from(e.target.files);
    setMedias(selectedMedias);
  };

  const handleInputPaste = (e) => {
    if (e.clipboardData.files[0]) {
      setMedias([e.clipboardData.files[0]]);
    }
  };

  const handleSendMessage = async () => {
    if (inputMessage.trim() === "" && medias.length === 0) return;

    setLoading(true);

    if (medias.length > 0) {
      const formData = new FormData();
      formData.append("fromMe", true);
      medias.forEach((media) => {
        formData.append("medias", media);
        if (inputMessage.trim()) {
          formData.append("body", inputMessage.trim());
        }
      });

      try {
        await api.post(`/messages/${ticketId}`, formData);
      } catch (err) {
        toastError(err);
      }

      setMedias([]);
    } else {
      const message = {
        read: 1,
        fromMe: true,
        mediaUrl: "",
        body: signMessage
          ? `*${user?.name}:*\n${inputMessage.trim()}`
          : inputMessage.trim(),
        quotedMsg: replyingMessage,
      };

      try {
        await api.post(`/messages/${ticketId}`, message);
      } catch (err) {
        toastError(err);
      }
    }

    setInputMessage("");
    setShowEmoji(false);
    setLoading(false);
    setReplyingMessage(null);
  };

  const handleStartRecording = async () => {
    setLoading(true);
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      await Mp3Recorder.start();
      setRecording(true);
      setLoading(false);
    } catch (err) {
      toastError(err);
      setLoading(false);
    }
  };

  const handleUploadAudio = async () => {
    setLoading(true);
    try {
      const [, blob] = await Mp3Recorder.stop().getMp3();
      if (blob.size < 10000) {
        setLoading(false);
        setRecording(false);
        return;
      }

      const formData = new FormData();
      const filename = `audio-record-site-${new Date().getTime()}.mp3`;
      formData.append("medias", blob, filename);
      formData.append("body", filename);
      formData.append("fromMe", true);

      await api.post(`/messages/${ticketId}`, formData);
    } catch (err) {
      toastError(err);
    }

    setRecording(false);
    setLoading(false);
  };

  const handleCancelAudio = async () => {
    try {
      await Mp3Recorder.stop().getMp3();
      setRecording(false);
    } catch (err) {
      toastError(err);
    }
  };

  const disableOption = () => {
    return loading || recording || ticketStatus !== "open";
  };

  const handleOpenMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event) => {
    setAnchorEl(null);
  };

  const renderReplyingMessage = (message) => {
    return (
      <div className={classes.replyginMsgWrapper}>
        <div className={classes.replyginMsgContainer}>
          <span
            className={clsx(classes.replyginContactMsgSideColor, {
              [classes.replyginSelfMsgSideColor]: !message.fromMe,
            })}
          ></span>
          <div className={classes.replyginMsgBody}>
            {!message.fromMe ? (
              <span className={classes.messageContactName}>
                {message.contact?.name}
              </span>
            ) : (
              <span className={classes.messageFromMe}>
                {i18n.t("messagesList.messageFromMe")}
              </span>
            )}
            {message.body}
          </div>
        </div>
        <IconButton
          aria-label="showRecorder"
          component="span"
          disabled={loading || ticketStatus !== "open"}
          onClick={() => setReplyingMessage(null)}
        >
          <ClearIcon className={classes.sendMessageIcons} />
        </IconButton>
      </div>
    );
  };

  const renderAttachedFileInfo = () => {
    const file = medias[0];
    const fileUrl = URL.createObjectURL(file);
    const fileType = file.type.split("/")[0];

    let preview;
    if (fileType === "image") {
      preview = <img src={fileUrl} alt="preview" className={classes.previewImage} />;
    } else if (fileType === "video") {
      preview = <video src={fileUrl} className={classes.previewVideo} controls />;
    } else if (fileType === "audio") {
      preview = <audio src={fileUrl} className={classes.previewAudio} controls />;
    } else {
      preview = <span>{file.name}</span>;
    }

    return (
      <div className={classes.attachedFileInfo}>
        {preview}
        <span style={{ marginRight: "10px" }}>{medias[0].name}</span>
        <IconButton
          aria-label="cancel-upload"
          component="span"
          onClick={() => setMedias([])}
        >
          <CancelIcon className={classes.sendMessageIcons3} />
        </IconButton>
      </div>
    );
  };

  return (
    <Paper
      square
      variant="outlined"
      elevation={0}
      className={classes.mainWrapper}
    >
      {replyingMessage && renderReplyingMessage(replyingMessage)}
      {medias.length > 0 && renderAttachedFileInfo()}
      <div className={classes.newMessageBox}>
        <IconButton
          aria-label="emojiPicker"
          component="span"
          disabled={loading || recording || ticketStatus !== "open"}
          onClick={(e) => setShowEmoji((prevState) => !prevState)}
          style={{ display: "none" }}
        ></IconButton>
        {showEmoji ? (
          <div className={classes.emojiBox}>
            <ClickAwayListener onClickAway={(e) => setShowEmoji(false)}>
              <Picker
                style={{
                  width: "100%",
                  fontColor: "#eee",
                }}
                showPreview={false}
                showSkinTones={false}
                onSelect={handleAddEmoji}
              />
            </ClickAwayListener>
          </div>
        ) : null}
        <Hidden only={["sm", "xs"]}>
          <IconButton
            aria-label="emojiPicker"
            component="span"
            disabled={loading || recording || ticketStatus !== "open"}
            onClick={(e) => setShowEmoji((prevState) => !prevState)}
          >
            <MoodIcon className={classes.sendMessageIcons} />
          </IconButton>

          <FileInput
            handleChangeMedias={handleChangeMedias}
            disableOption={disableOption}
            medias={medias}
            setMedias={setMedias}
          />

          <FormControlLabel
            style={{ marginRight: 7, color: "gray" }}
            label={i18n.t("messagesInput.signMessage")}
            labelPlacement="start"
            control={
              <Switch
                size="small"
                checked={signMessage}
                onChange={(e) => {
                  setSignMessage(e.target.checked);
                }}
                name="showAllTickets"
                color="primary"
              />
            }
          />
        </Hidden>
        <Hidden only={["md", "lg", "xl"]}>
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleOpenMenuClick}
          >
            <MoreVert></MoreVert>
          </IconButton>
          <Menu
            id="simple-menu"
            keepMounted
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuItemClick}
          >
            <MenuItem onClick={handleMenuItemClick}>
              <IconButton
                aria-label="emojiPicker"
                component="span"
                disabled={loading || recording || ticketStatus !== "open"}
                onClick={(e) => setShowEmoji((prevState) => !prevState)}
              >
                <MoodIcon className={classes.sendMessageIcons} />
              </IconButton>
            </MenuItem>
            <MenuItem onClick={handleMenuItemClick}>
              <FileInput
                handleChangeMedias={handleChangeMedias}
                disableOption={disableOption}
                medias={medias}
                setMedias={setMedias}
              />
            </MenuItem>
            <MenuItem onClick={handleMenuItemClick}>
              <FormControlLabel
                style={{ marginRight: 7, color: "gray" }}
                label={i18n.t("messagesInput.signMessage")}
                labelPlacement="start"
                control={
                  <Switch
                    size="small"
                    checked={signMessage}
                    onChange={(e) => {
                      setSignMessage(e.target.checked);
                    }}
                    name="showAllTickets"
                    color="primary"
                  />
                }
              />
            </MenuItem>
          </Menu>
        </Hidden>

        <CustomInput
          loading={loading}
          inputRef={inputRef}
          ticketStatus={ticketStatus}
          inputMessage={inputMessage}
          setInputMessage={setInputMessage}
          handleSendMessage={handleSendMessage}
          handleInputPaste={handleInputPaste}
          disableOption={disableOption}
        />

        <ActionButtons
          inputMessage={inputMessage}
          medias={medias}
          loading={loading}
          recording={recording}
          ticketStatus={ticketStatus}
          handleSendMessage={handleSendMessage}
          handleCancelAudio={handleCancelAudio}
          handleUploadAudio={handleUploadAudio}
          handleStartRecording={handleStartRecording}
        />
      </div>
    </Paper>
  );
};

export default withWidth()(MessageInput);
