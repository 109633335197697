import React, { useState, useEffect, memo } from "react";
import { Avatar, CardHeader } from "@material-ui/core";
import { i18n } from "../../translate/i18n";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";

const useStyles = makeStyles((theme) => ({
  headerRoot: {
    padding: "8px 16px",
  },
  cardHeader: {
    fontSize: 14,    
    marginLeft: theme.spacing(-3),
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0px",
    },
  },  
  headerTitle: {
    color: theme.palette.text.primary,
  },
  headerSubheader: {
    color: theme.palette.text.secondary,
  },
}));

const SmallAvatar = withStyles((theme) => ({
  root: {
    width: 19,
    height: 19,
    borderRadius: 0,
    border: `0px solid ${theme.palette.background.paper}`,
  },
}))(Avatar);

const TicketInfo = memo(({ contact, ticket, onClick }) => {
  const classes = useStyles();
  const { user } = ticket;
  const [contactName, setContactName] = useState("");

  useEffect(() => {
    if (contact) {
      setContactName(contact.name);
      if (document.body.offsetWidth < 600) {
        if (contact.name.length > 10) {
          const truncatedName = contact.name.substring(0, 10) + "...";
          setContactName(truncatedName);
        }
      }
    }
  }, [contact]);

  const getAvatarChannel = (channel) => {
    switch (channel) {
      case "facebook":
        return <FacebookIcon style={{ color: "#1877F2" }} />;
      case "whatsapp":
        return <WhatsAppIcon style={{ color: "#25D366" }} />;
      case "instagram":
        return <InstagramIcon style={{ color: "#C13584" }} />;
      default:
        return null;
    }
  };

  return (
    <CardHeader className={classes.cardHeader}
      onClick={onClick}
      style={{ cursor: "pointer" }}
      classes={{
        root: classes.headerRoot,
        title: classes.headerTitle,
        subheader: classes.headerSubheader,
      }}
      avatar={
        <Badge
          overlap="circle"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          badgeContent={getAvatarChannel(ticket?.channel)}
        >
          <Avatar
            src={ticket?.contact?.profilePicUrl}
            style={{
              outline: `1px solid ${ticket.queue?.color || "#7C7C7C"}`,
              outlineOffset: "3px",
              width: "46px",
              height: "46px",
            }}
          />
        </Badge>
      }
      titleTypographyProps={{ noWrap: true }}
      subheaderTypographyProps={{ noWrap: true }}
      title={contactName}
      subheader={
        ticket.user
          ? `${i18n.t("messagesList.header.assignedTo")} ${user.name}`
          : i18n.t("messagesList.header.closed")
      }
    />
  );
});

export default TicketInfo;
