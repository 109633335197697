import React, { useState, useRef } from "react";
import Popover from "@material-ui/core/Popover";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import { makeStyles } from "@material-ui/core/styles";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import VolumeDownIcon from "@material-ui/icons/VolumeDown";
import { Grid, Slider } from "@material-ui/core";
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VolumeMuteIcon from '@material-ui/icons/VolumeMute';


const useStyles = makeStyles((theme) => ({
  tabContainer: {
    padding: theme.spacing(2),
  },
  popoverPaper: {
    width: "100%",
    maxWidth: 350,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      maxWidth: 270,
    },
  },
  icons: {
    color: "#fff",
  },
  sliderContainer: {
    display: "flex",
    alignItems: "center",
  },
  sliderValue: {
    marginLeft: theme.spacing(1),
    width: 30,
    fontSize: 12,
    textAlign: "center",
  },

}));

const NotificationsVolume = ({ volume: initialVolume, setVolume: setParentVolume }) => {
  const classes = useStyles();

  const currentVolume = localStorage.getItem('volume') 
    ? parseFloat(localStorage.getItem('volume')) 
    : initialVolume;

  const [volume, setVolume] = useState(currentVolume);

  const anchorEl = useRef();
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleClickAway = () => {
    setIsOpen(false);
  };

  const handleVolumeChange = (event, newValue) => {
    setVolume(newValue);
    if(setParentVolume) {
      setParentVolume(newValue);
    }
    localStorage.setItem("volume", newValue);
  };

  return (
    <>
      <IconButton
        className={classes.icons}
        onClick={handleClick}
        ref={anchorEl}
        aria-label="Open Notifications"
      >
        {volume === 0 && (
          <VolumeOffIcon color="inherit" />
        )}
        {volume > 0 && volume <= 0.4 && (
          <VolumeMuteIcon color="inherit" />
        )}
        {volume > 0.4 && volume <= 0.9 && (
          <VolumeDownIcon color="inherit" />
        )}
        {volume > 0.9 && (
          <VolumeUpIcon color="inherit" />
        )}
      </IconButton>
      <Popover
        disableScrollLock
        open={isOpen}
        anchorEl={anchorEl.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        classes={{ paper: classes.popoverPaper }}
        onClose={handleClickAway}
      >
        <List dense className={classes.tabContainer}>
          <Grid container spacing={2}>
            <Grid item xs className={classes.sliderContainer}>
              <Slider
                value={volume}
                aria-labelledby="continuous-slider"
                step={0.01}
                min={0}
                max={1}
                onChange={handleVolumeChange}
              />
              <span className={classes.sliderValue}>
                {(volume * 100).toFixed()}%
              </span>
            </Grid>
          </Grid>
        </List>
      </Popover>
    </>
  );
};

export default NotificationsVolume;
