import React, { useEffect, useState } from 'react';
import toastError from "../../errors/toastError";
import { Button, Typography, Hidden } from "@material-ui/core";
import { i18n } from "../../translate/i18n";
import map from "../../assets/map.png";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  thumbnail: { 
    width: "100px", 
    height: "100px", 
    borderTopLeftRadius: "5px",
    borderBottomLeftRadius: "5px",
    objectFit: "cover",
  },

  locationContainer: {
    fontSize: "14px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
    textDecoration: "none",
    backgroundColor: theme.palette.quotedAdContainerLeft,
    borderRadius: "5px",
    width: "320px"
  },
  locationContent: {
    display: "flex",
    flexDirection: "column",
    padding: "10px",
  },

  locationButtons: {
    position: "relative",
    display: "flex",
    justifyContent:"space-between",
  },
}));

const LocationPreview = ({ image, link, description }) => {
  const classes = useStyles();
  
  useEffect(() => {}, [image, link, description]);

  const handleLocation = async () => {
    try {
      window.open(link);
    } catch (err) {
      toastError(err);
    }
  };

  const [copied, setCopied] = useState(false);
  const handleCopyDescription = () => {
    navigator.clipboard.writeText(description.trim());
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };
  
  const handleCopyLink = () => {
    navigator.clipboard.writeText(link.trim());
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };  

  return (
    <>
      <div className={classes.locationContainer} onClick={handleLocation}>
        {image && (
          <img
            src={image}
            alt="loc"
            className={classes.thumbnail}
            onError={(e) => {
              e.target.onerror = null; 
              e.target.src = map;
            }}
          />
        )}
        <div className={classes.locationContent}>
          {description && (
            <Typography
              variant="subtitle1"
              color="primary"
              gutterBottom
              dangerouslySetInnerHTML={{
                __html: description.replace("\\n", "<br />"),
              }}
            />
          )}
        </div>
      </div>
      <div className={classes.locationButtons}>
        <Button
          size="small"
          color="primary"
          onClick={handleCopyLink}
          disabled={!link}
        >
          {i18n.t("messagesList.location.link")}
        </Button>
        <Button
          size="small"
          color="primary"
          onClick={handleCopyDescription}
          disabled={!description}
        >
          {i18n.t("messagesList.location.copy")}
        </Button>
        <Hidden only={["sm", "xs"]}>
          {copied && (
            <span
              style={{
                position: "absolute",
                top: "64px",
                left: "33%",
                button: "2",
                padding: "4px 8px",
                borderRadius: "4px",
              }}
            >
              {i18n.t("contactDrawer.copiedText")}
            </span>
          )}
        </Hidden>
      </div>
    </>
  );
};

export default LocationPreview;
