import React, { useState, useEffect, useRef, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { parseISO, format, isSameDay } from "date-fns";
import clsx from "clsx";

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import Badge from "@material-ui/core/Badge";
//import Box from "@material-ui/core/Box";
import IconButton from '@material-ui/core/IconButton';
import UndoSharpIcon from '@material-ui/icons/UndoSharp';
import RedoSharpIcon from '@material-ui/icons/RedoSharp';
import NavigateNextOutlinedIcon from '@material-ui/icons/NavigateNextOutlined';
import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import MarkdownWrapper from "../MarkdownWrapper";
import { Tooltip } from "@material-ui/core";
import { AuthContext } from "../../context/Auth/AuthContext";
import { TicketsContext } from "../../context/Tickets/TicketsContext";
import toastError from "../../errors/toastError";
import { v4 as uuidv4 } from "uuid";

import GroupIcon from '@material-ui/icons/Group';
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";

import AndroidIcon from "@material-ui/icons/Android";

import { makeStyles, withStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  ticket: {
    position: "relative",
  },
  pendingTicket: {
    cursor: "unset",
  },
  noTicketsDiv: {
    display: "flex",
    height: "100px",
    margin: 40,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  noTicketsText: {
    backgroundColor: "#fff",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    height: "100%",
    textAlign: "center",
    color: "rgb(104, 121, 146)",
    fontSize: "14px",
    lineHeight: "1.4",
  },
  noTicketsTitle: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "600",
    margin: "0px",
  },
  lastMessageTime: {
    justifySelf: "flex-end",
  },
  closedBadge: {
    position: "absolute",
    marginRight: 5,
    right: 40,
    bottom: 12,
  },
  contactLastMessage: {
    display: "flex",
    justifyContent: "space-between",
  },
  newMessagesCount: {
    height: "21px",
    fontSize: 12,
    fontWeight: 500,
    paddingLeft: 7,
    paddingRight: 7,
    borderRadius: 12,
    marginLeft: "5px",
    marginBottom: "4px",
    alignItems: "center",
    justifyContent: "center"
  },
  bottomButton: {
    position: "relative",
    marginRight: -13,
    color: theme.palette.background.bottomButton,
    fontSize: 10,
  },
  ticketQueueColor: {
    flex: "none",
    width: "8px",
    height: "100%",
    position: "absolute",
    top: "0%",
    left: "0%",
    borderRadius: "0px 5px 5px 0px",
  },
  userTag: {
    color: "#fff",
    padding: "0 6px",
    borderRadius: 10,
    fontSize: "0.8em",
    marginRight: "4px",
    alignSelf: "center",
    marginLeft: "auto",
  },
  userTag2: {
    position: "absolute",
    marginRight: 5,
    right: 47,
    bottom: -2,
    color: "#a4a4a4",
    fontSize: "0.7em",
  },
  queueTag: {
    background: "#FCFCFC",
    color: "#000",
    marginRight: 1,
    padding: 1,
    fontWeight: 'bold',
    paddingLeft: 5,
    paddingRight: 5,
    borderRadius: 3,
    fontSize: "0.6em",
    whiteSpace: "nowrap"
  },
  connectionTag: {
    background: "green",
    color: "#FFF",
    marginRight: 1,
    padding: 1,
    fontWeight: 'bold',
    paddingLeft: 5,
    paddingRight: 5,
    borderRadius: 3,
    fontSize: "0.8em",
    whiteSpace: "nowrap"
  },
  ticketInfo: {
    position: "relative",
    top: -13
  },
  secondaryContentSecond: {
    display: 'flex',
    alignItems: "flex-start",
    flexWrap: "wrap",
    flexDirection: "row",
    alignContent: "flex-start",
  },
  ticketInfo1: {
    position: "relative",
    top: 13,
    right: 0
  },
  Radiusdot: {
    "& .MuiBadge-badge": {
      borderRadius: 2,
      position: "inherit",
      height: 16,
      margin: 2,
      padding: 3
    },
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      transform: "scale(1) translate(0%, -40%)",
    },
  },
  connectionIcon: {
    marginRight: theme.spacing(1)
  }
}));

const SmallAvatar = withStyles((theme) => ({
  root: {
    width: 19,
    height: 19,
    borderRadius: 0,
    border: `0px solid ${theme.palette.background.paper}`,
  },
}))(Avatar);

const getAvatarChannel = (channel) => {
  if (channel === "facebook") {
    return <FacebookIcon style={{ color: "#1877F2" }} />
  }

  if (channel === "whatsapp") {
    return <WhatsAppIcon style={{ color: "#25D366" }} />
  }

  if (channel === "instagram") {
    return <InstagramIcon style={{ color: "#C13584" }} />
  }

};

const TicketListItem = ({ handleChangeTab, ticket }) => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [ticketUser, setTicketUser] = useState(null);
  const [ticketQueueName, setTicketQueueName] = useState(null);
  const [ticketQueueColor, setTicketQueueColor] = useState(null);
  const [tag, setTag] = useState([]);
  const [whatsAppName, setWhatsAppName] = useState(null);
  const { ticketId } = useParams();
  const isMounted = useRef(true);
  const { user } = useContext(AuthContext);
  const { profile } = user;
  const [showBadge, setShowBadge] = useState(false);

  useEffect(() => {
    let timerId;
    if (ticket.unreadMessages > 0) {
      timerId = setTimeout(() => {
        setShowBadge(true);
      }, 300);
    } else {
      setShowBadge(false);
    }

    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    }
  }, [ticket.unreadMessages]);

  useEffect(() => {
    async function fetchData() {
      if (ticket.userId && ticket.user) {
        setTicketUser(ticket.user?.name);
      }
      setTicketQueueName(ticket.queue?.name);
      setTicketQueueColor(ticket.queue?.color);

      return () => {
        isMounted.current = false;
      };
    }
    fetchData();
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const fetchTicket = async () => {
        try {
          const { data } = await api.get("/tickets/" + ticket.id);

          if (data.whatsappId && data.whatsapp) {
            setWhatsAppName(data.whatsapp.name);
          }

          setTag(data?.tags);

        } catch (err) {
        }
      };
      fetchTicket();
    }, 500);
    return () => {
      if (delayDebounceFn !== null) {
        clearTimeout(delayDebounceFn);
      }
    };
  }, [ticketId, user, history]);

  const handleReopenTicket = async (id) => {
    setLoading(true);
    try {
      await api.put(`/tickets/${id}`, {
        status: "open",
        userId: user?.id,
        queueId: ticket?.queue?.id
      });
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
    if (isMounted.current) {
      setLoading(false);
    }
    handleChangeTab(null, "open");
    history.push(`/tickets/${ticket.uuid}`);
  };

  const handleAceptTicket = async (id) => {
    setLoading(true);
    try {
      await api.put(`/tickets/${id}`, {
        status: "open",
        userId: user?.id,
      });

      let settingIndex;

      try {
        const { data } = await api.get("/settings/");
        settingIndex = data.filter((s) => s.key === "sendGreetingAccepted");
      } catch (err) {
        toastError(err);
      }

      if (settingIndex[0].value === "enabled" && !ticket.isGroup) {
        handleSendMessage(ticket.id);
      }

    } catch (err) {
      setLoading(false);
      toastError(err);
    }
    if (isMounted.current) {
      setLoading(false);
    }

    handleChangeTab(null, "open");
    history.push(`/tickets/${ticket.uuid}`);
  };

  const handleSendMessage = async (id) => {

    // agregado obtención de hora para determinar el saludo. Esto también se obtiene de Mustache.ts pero sin traducción
    let ms = "";
    const hh = new Date().getHours();

    if (hh >= 6) { ms = i18n.t("ticketListItem.greeting.morning"); }
    if (hh > 11) { ms = i18n.t("ticketListItem.greeting.afternoon"); }
    if (hh > 17) { ms = i18n.t("ticketListItem.greeting.night"); }
    if (hh > 23 || hh < 6) { ms = i18n.t("ticketListItem.greeting.morning"); }

    //      const msg = `{{ms}} *{{name}}*, ${(i18n.t("ticketListItem.myNameIs"))} *${user?.name}* ${(i18n.t("ticketListItem.assistant"))}`;
    //      const msg = `${ms} *{{name}}*, ${(i18n.t("ticketListItem.myNameIs"))} *${user?.name}* ${(i18n.t("ticketListItem.assistant"))}`;
    const msg = `${ms}! 😊\n${(i18n.t("ticketListItem.myNameIs"))} *${user?.name}*. ${(i18n.t("ticketListItem.assistant"))}`;
    const message = {
      read: 1,
      fromMe: true,
      mediaUrl: "",
    //  body: `*${(i18n.t("ticketListItem.virtualAssistant"))}*\n${msg.trim()}`,
      body: `${msg.trim()}`,
    };
    try {
      await api.post(`/messages/${id}`, message);
    } catch (err) {
      toastError(err);
    }
  };

  const handleViewTicket = async id => {
    setLoading(true);
    try {
      await api.put(`/tickets/${id}`, {
        status: "pending",
        userId: user?.id,
      });
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
    if (isMounted.current) {
      setLoading(false);
    }
    history.push(`/tickets/${ticket.uuid}`);
  };

  const handleReturnTicket = async (id) => {
    setLoading(true);
    try {
      await api.put(`/tickets/${id}`, {
        status: "pending",
        userId: user?.id,
      });
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
    if (isMounted.current) {
      setLoading(false);
    }
    history.push(`/`);
  };

  const handleSelectTicket = (id) => {
    history.push(`/tickets/${ticket.uuid}`);
  };

// Función para verificar y reemplazar el contenido del mensaje
const getMessageContent = (message) => {
  switch (message) {
    case 'imageType':
      return `📷 ${i18n.t("ticketListItem.message.photo")}`;
    case 'videoType':
      return `🎥 ${i18n.t("ticketListItem.message.video")}`;
    case 'audioType':
      return `🎤 ${i18n.t("ticketListItem.message.sound")}`;
    case 'documentType':
      return `📄 ${i18n.t("ticketListItem.message.document")}`;
    case 'locationType':
      return `📍 ${i18n.t("ticketListItem.message.location")}`;
    case 'contactType':
      return `👤 ${i18n.t("ticketListItem.message.contact")}`;
    case 'contactsArrayType':
      return `👥 ${i18n.t("ticketListItem.message.contactsArray")}`;
    case 'fileType':
      return `📎 ${i18n.t("ticketListItem.message.file")}`;
    default:
      return message;
  }
};

  // Obtener el contenido del mensaje
  const messageContent = getMessageContent(ticket.lastMessage || "");

  return (
    <React.Fragment key={ticket.id}>
      <div style={{ marginLeft: -5 }}>
        <ListItem
          dense
          button
          onClick={e => {
            if (ticket.status === "pending") return;
            handleSelectTicket(ticket.id);
          }}
          selected={ticketId && +ticketId === ticket.id}
          className={clsx(classes.ticket, {
            [classes.pendingTicket]: ticket.status === "pending",
          })}
        >

          <ListItemAvatar>
            <Badge
              overlap="circle"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              badgeContent={getAvatarChannel(ticket?.channel)}
            >
              <Avatar
                src={ticket?.contact?.profilePicUrl}
                style={{
                  outline: `1px solid ${ticket.queue?.color || "#7C7C7C"}`,
                  outlineOffset: '3px',
                  width: "46px",
                  height: "46px",
                }}
              />
            </Badge>
          </ListItemAvatar>

          <ListItemText
            disableTypography
            primary={
              <span className={classes.contactLastMessage}>
                <Typography
                  noWrap
                  component="span"
                  variant="body2"
                  color="textPrimary"
                >
                  {/*ticket.isGroup && ticket.channel === "whatsapp" && <GroupIcon fontSize="small" style={{ color: grey[700], marginBottom: '-5px', marginLeft: '5px' }} />} &nbsp;
                                {ticket.channel && <ConnectionIcon width="10" height="10" className={classes.connectionIcon} connectionType={ticket.channel} />*/}
                  {ticket.contact.name}
                </Typography>
                <Badge
                  style={{ backgroundColor: ticket.queue?.color || "#7c7c7c" }}
                  className={classes.userTag}>{ticket.queue?.name || i18n.t("ticketListItem.noQueue")}
                </Badge>
                {ticket.lastMessage && (
                  <Typography
                    className={classes.lastMessageTime}
                    component="span"
                    variant="body2"
                    color="textSecondary"
                  >
                    {isSameDay(parseISO(ticket.updatedAt), new Date()) ? (
                      <>{format(parseISO(ticket.updatedAt), "HH:mm")}</>
                    ) : (
                      <>{format(parseISO(ticket.updatedAt), "dd/MM/yyyy")}</>
                    )}
                  </Typography>
                )}

                {/*<ListItemSecondaryAction>
                  <Box className={classes.ticketInfo1}>{renderTicketInfo()}</Box>
                </ListItemSecondaryAction>*/}
              </span>
            }
            secondary={
              <span className={classes.contactLastMessage}>
                <Typography
                  noWrap
                  component="span"
                  variant="body2"
                  color="textSecondary"
                >
                  {ticket.lastMessage ? (
                    <MarkdownWrapper>{messageContent}</MarkdownWrapper>
                  ) : (
                    <br />
                  )}
                  <span className={classes.secondaryContentSecond} >
                    {ticket.status === "closed" && (
                      <div className={classes.userTag2}>{(i18n.t("ticketListItem.bottomButton.closed"))} - Conexión: {whatsAppName || i18n.t("ticketListItem.noConection")}</div>
                    )}
                    {ticketUser && ticket.status === "open" && (
                      <div className={classes.userTag2}> En proceso - {profile === "admin" && 'Usuario: ' + ticketUser + ' - '} Conexión {whatsAppName || i18n.t("ticketListItem.noConection")}</div>
                    )}
                    {ticket.status === "pending" && (
                      <div className={classes.userTag2}>{(i18n.t("ticketListItem.bottomButton.pending"))} - Conexión: {whatsAppName || i18n.t("ticketListItem.noConection")}</div>
                    )}
                    {/*{ticketUser ? <Badge style={{ backgroundColor: "#000000" }} className={classes.connectionTag}>{ticketUser}</Badge> : <br></br>}
                                    <Badge style={{ backgroundColor: ticket.queue?.color || "#7c7c7c" }} className={classes.connectionTag}>{ticket.queue?.name || "SEM FILA"}</Badge>
                                    {ticketUser ? <Badge style={{ backgroundColor: "#000000" }} className={classes.userTag2}>{ticketUser} - </Badge> : <br></br>}{whatsAppName ? <Badge className={classes.userTag2}>{whatsAppName}</Badge> : <br></br>}*/}
                  </span>
                  {/*<span className={classes.secondaryContentSecond} >
                                    {
                                        tag?.map((tag) => {
                                            return (
                                                <ContactTag tag={tag} key={`ticket-contact-tag-${ticket.id}-${tag.id}`} />
                                            );
                                        })
                                    }
                                </span>*/}
                </Typography>
                {showBadge ? (
                  <Badge
                    style={{
                      backgroundColor: "#1FD56C",
                      color: "#fff",
                    //  fontSize: "0.9em",
                      display: "flex",

                    }}
                    className={classes.newMessagesCount}
                  >
                    {ticket.unreadMessages}
                  </Badge>
                ) : (
                    ticket.isBot && !ticketUser && (
                        <AndroidIcon
                          fontSize="small"
                          style={{ color: "#1FD56C" }}
                        />
                    )
                )}

              </span>
            }
          />

          <ListItemSecondaryAction>

          </ListItemSecondaryAction>

          {ticket.status === "pending" && (
            <Tooltip arrow placement="right" title={(i18n.t("actionIcon.acept"))}>
              <IconButton
                className={classes.bottomButton}
                color="grey"
                onClick={e => handleAceptTicket(ticket.id)} >
                <NavigateNextOutlinedIcon />
              </IconButton>
            </Tooltip>
          )}
          {ticket.status === "open" && (
            <Tooltip arrow placement="right" title={(i18n.t("actionIcon.return"))}>
              <IconButton
                className={classes.bottomButton}
                color="grey"
                onClick={e => handleReturnTicket(ticket.id)} >
                <UndoSharpIcon />
              </IconButton>
            </Tooltip>
          )}

          {ticket.status === "closed" && (
            <Tooltip arrow placement="right" title={(i18n.t("actionIcon.reopen"))}>
              <IconButton
                className={classes.bottomButton}
                color="grey"
                onClick={e => handleReopenTicket(ticket.id)} >
                <RedoSharpIcon />
              </IconButton>
            </Tooltip>
          )}
        </ListItem>
        <Divider variant="inset" component="li" style={{ transform: 'scaleY(0.7)' }} />
      </div>
    </React.Fragment>
  );
};

export default TicketListItem;