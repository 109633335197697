import React, { useState, useEffect } from "react";
import { makeStyles, TextField, Grid } from "@material-ui/core";
import { Formik, Form, FastField, FieldArray } from "formik";
import { toast } from "react-toastify";
import { isArray } from "lodash";
import NumberFormat from "react-number-format";
import ButtonWithSpinner from "../ButtonWithSpinner";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  fullWidth: {
    width: "100%",
  },
  textfield: {
    width: "100%",
  },
  row: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  control: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  control2: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingBottom: 2,
  },
  label: {
    marginLeft: '9px',
  },  
  buttonContainer: {
    textAlign: "right",
    padding: theme.spacing(1),
  },
}));

function SchedulesForm(props) {
  const { initialValues, onSubmit, loading, labelSaveButton } = props;
  const classes = useStyles();

  const [schedules, setSchedules] = useState([
    {
      weekday: (i18n.t("queueModal.weekdays.monday")),
      weekdayEn: "monday",
      startTime: "",
      endTime: "",
    },
    {
      weekday: (i18n.t("queueModal.weekdays.tuesday")),
      weekdayEn: "tuesday",
      startTime: "",
      endTime: "",
    },
    {
      weekday: (i18n.t("queueModal.weekdays.wednesday")),
      weekdayEn: "wednesday",
      startTime: "",
      endTime: "",
    },
    {
      weekday: (i18n.t("queueModal.weekdays.thursday")),
      weekdayEn: "thursday",
      startTime: "",
      endTime: "",
    },
    {
      weekday: (i18n.t("queueModal.weekdays.friday")), 
      weekdayEn: "friday", 
      startTime: "", 
      endTime: "" 
    },
    {
      weekday: (i18n.t("queueModal.weekdays.saturday")),
      weekdayEn: "saturday", 
      startTime: "", 
      endTime: "" 
    },
    {
      weekday: (i18n.t("queueModal.weekdays.sunday")),
      weekdayEn: "sunday",      
      startTime: "",
      endTime: "" 
    },
  ]);

  const [outOfHoursMessage, setOutOfHoursMessage] = useState(null);

  useEffect(() => {
    if (isArray(initialValues.schedules) && initialValues.schedules.length > 0) {
      setSchedules(initialValues.schedules);
    }
    if (initialValues.outOfHoursMessage) {
      setOutOfHoursMessage(initialValues.outOfHoursMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);
const isAnyScheduleConfigured = (schedules) => {
  return schedules.some(schedule => {
    return schedule.startTime !== '' && schedule.endTime !== '';
  });
};

const isScheduleValid = (schedules) => {
  const timeRegex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/; // Formato HH:MM
  return schedules.every(schedule => {
    return (schedule.startTime === '' && schedule.endTime === '') || 
           (timeRegex.test(schedule.startTime) && timeRegex.test(schedule.endTime));
  });
};

const handleSubmit = (data) => {
  // Verifica que si uno de los campos de horario está lleno, el otro también lo esté
  const isScheduleComplete = data.schedules.every(schedule => {
    const bothEmpty = schedule.startTime === '' && schedule.endTime === '';
    const bothFilled = schedule.startTime !== '' && schedule.endTime !== '';
    return bothEmpty || bothFilled;
  });

  // Verifica que los horarios llenos tengan un formato de hora correcto
  const isScheduleValidated = isScheduleValid(data.schedules);

  // Si algún horario está incompleto o no tiene un formato válido, muestra un error
  if (!isScheduleComplete || !isScheduleValidated) {
    toast.error(i18n.t("queueModal.schedules.incompleteSchedules"));
    return;
  }

  // Determina si al menos un horario está configurado
  const isScheduleConfigured = isAnyScheduleConfigured(data.schedules);

  // Si hay al menos un horario configurado y el mensaje fuera de horario está vacío
  if (isScheduleConfigured && (!data.outOfHoursMessage || data.outOfHoursMessage.trim() === '')) {
    toast.error(i18n.t("queueModal.schedules.setSchedules"));
    return;
  }

  // Si todo está correcto, ejecuta la función onSubmit
  onSubmit(data);
};


  return (
    <Formik
      enableReinitialize
      className={classes.fullWidth}
      initialValues={{ schedules, outOfHoursMessage }}
      onSubmit={({ schedules, outOfHoursMessage }) =>
        setTimeout(() => {
          handleSubmit({ schedules, outOfHoursMessage });
        }, 500)
      }
    >
      {({ touched, errors, values }) => (
        <Form>
          <DialogContent dividers className={classes.formControl}>
          <div>
            <FieldArray
              name="schedules"
              render={(arrayHelpers) => (
                <Grid spacing={4} container>
                  {values.schedules.map((item, index) => {
                    return (
                      <Grid key={index} container item xs={12} md={6}>
                        <Grid className={classes.control} xs={12} item>
                          <FastField
                            as={TextField}
                            label={i18n.t("queueModal.weekdays.weekday")}
                            name={`schedules[${index}].weekday`}
                            disabled
                            variant="outlined"
                            className={classes.fullWidth}
                            margin="dense"
                          />
                        </Grid>
                        <Grid item xs={12} md={6} className={classes.control}>
                          <FastField
                            name={`schedules[${index}].startTime`}
                          >
                            {({ field }) => (
                              <NumberFormat
                                {...field}
                                label={i18n.t("queueModal.schedules.startTime")}
                                variant="outlined"
                                margin="dense"
                                customInput={TextField}
                                format="##:##"
                                className={classes.fullWidth}
                              />
                            )}
                          </FastField>
                        </Grid>
                        <Grid item xs={12} md={6} className={classes.control}>
                          <FastField
                            name={`schedules[${index}].endTime`}
                          >
                            {({ field }) => (
                                <NumberFormat
                                  {...field}
                                  label={i18n.t("queueModal.schedules.endTime")}
                                  variant="outlined"
                                  margin="dense"
                                  customInput={TextField}
                                  format="##:##"
                                  className={classes.fullWidth}
                                />
                            )}
                          </FastField>
                        </Grid>
                      </Grid>
                    );
                  })}
      {
        isAnyScheduleConfigured(values.schedules) && isScheduleValid(values.schedules) && (                   
                  <Grid container  xs={12} md={6} item>
            <FastField
              as={TextField}
              label={i18n.t("queueModal.form.outOfHoursMessage")}
              type="text"
              multiline
              rows={4}
              fullWidth
              name="outOfHoursMessage"
              error={touched.outOfHoursMessage && Boolean(errors.outOfHoursMessage)}
              helperText={touched.outOfHoursMessage && errors.outOfHoursMessage}
              variant="outlined"
              margin="dense"
              className={classes.control2}
              InputLabelProps={{
                className: classes.label
              }}
            />
          </Grid>
        )
      }
                </Grid>
              )}
            ></FieldArray>
         </div>
        
          </DialogContent>
          <DialogActions>
            <ButtonWithSpinner
              loading={loading}
              type="submit"
              color="primary"
              variant="contained"
            >
              {i18n.t("next")}
            </ButtonWithSpinner>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
}

export default SchedulesForm;
