import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Badge from "@material-ui/core/Badge";
import MoveToInboxIcon from "@material-ui/icons/MoveToInbox";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
//import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import MessageSharpIcon from "@material-ui/icons/MessageSharp";
import ClockIcon from "@material-ui/icons/AccessTime";
import UsersIcon from "@material-ui/icons/People";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid"

import NewTicketModal from "../NewTicketModal";
import TicketsList from "../TicketsList";
import TabPanel from "../TabPanel";

import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";
import TicketsQueueSelect from "../TicketsQueueSelect";
import { Button } from "@material-ui/core";
import { TagsFilter } from "../TagsFilter";
import { UsersFilter } from "../UsersFilter";
import api from "../../services/api";

const useStyles = makeStyles((theme) => ({
  ticketsWrapper: {
    position: "relative",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflow: "hidden",
  },

  ticketsWrapper1: {
    position: "relative",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflow: "hidden",
    borderLeft: 0,
    borderTop: 0,
    borderBottom: 0,
  },

  tabsHeader: {
    height: "72px",
    flex: "none",
    backgroundColor: theme.palette.tabHeaderBackground,
    borderLeft: 0,
    borderRight: 0,
    borderTop: 0,
  },

  settingsIcon: {
    alignSelf: "center",
    marginLeft: "auto",
    padding: 8,
  },

  tab: {
    minWidth: 120,
    width: 120,
  },

  tabPanelItem: {
    minWidth: 120,
    fontSize: 11,
    marginLeft: 0,
//    background: theme.palette.optionsBackground,
  },
  
  tabLabel: {
    color: theme.palette.messageIconsInactive,
  }, 

  tabLabelActive: {
    color: theme.palette.messageIconsActive,
  },

  ticketOptionsBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: "5px",
    paddingLeft: "8px",
    paddingRight: "7px",
    paddingBottom: "5px",
  },

  searchInputWrapper: {
    flex: 1,
    display: "flex",
    border: `1px solid ${theme.palette.inputSearchBorder}`,
    borderRadius: 4,
    padding: 3,
    marginRight: theme.spacing(1),
  },

  searchIcon: {
    color: "grey",
    marginLeft: 5,
    marginRight: 7,
    alignSelf: "center",
  },

  searchInput: {
    flex: 1,
    border: "none",
    borderRadius: 0,
  },

  opensBadge2: {
    right: "-10px",
    backgroundColor: "#319614",
    color: "#fff",
  },
  
   pendingsBadge2: {
    right: "-10px",
    backgroundColor: "#f44336",
    color: "#fff",
  }, 
  
  tabBadge1: {
    height: "21px",
    fontSize: 12,
    fontWeight: 500, 
    paddingLeft: 7,
    paddingRight: 7,
    borderRadius: 12,
//    alignSelf: "center",
    marginLeft: "-2px",
    marginBottom: "23px",
    alignItems: "center",
    justifyContent: "center",
  },
  
  opensBadge: {
    height: "21px",
    fontSize: 12,
    fontWeight: 500, 
    paddingLeft: 7,
    paddingRight: 7,
    borderRadius: 12,
//    alignSelf: "center",
    marginLeft: "71px",
    marginBottom: "21px",
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
  },  
  
   pendingsBadge: {
    height: "21px",
    fontSize: 12,
    fontWeight: 500, 
    paddingLeft: 7,
    paddingRight: 7,
    borderRadius: 12,
//    alignSelf: "center",
    marginLeft: "60px",
    marginBottom: "21px",
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
  }, 

  pendingsBadge1: {
    right: "-10px",
    backgroundColor: "#f44336",
    color: "#fff",
  },

  show: {
    display: "block",
  },

  hide: {
    display: "none !important",
  },
}));

const TicketsManagerTabs = () => {
  const classes = useStyles();
  const history = useHistory();

  const [searchParam, setSearchParam] = useState("");
  const [tab, setTab] = useState("open");
  const [tabOpen, setTabOpen] = useState("open");
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [showAllTickets, setShowAllTickets] = useState(false);
  const searchInputRef = useRef();
  const { user } = useContext(AuthContext);
  const { profile } = user;

  const [openCount, setOpenCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);
  const [groupCount, setGroupCount] = useState(0);

  const userQueueIds = user.queues.map((q) => q.id);
  const [selectedQueueIds, setSelectedQueueIds] = useState(userQueueIds || []);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [msgIsGroup, setMsgIsGroup] = useState(true);

  useEffect(() => {
    if (user.profile.toUpperCase() === "ADMIN") {
      setShowAllTickets(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function fetchData() {
      const { data } = await api.get("/settings/CheckMsgIsGroup");

      if (data.value === "disabled") {
        setMsgIsGroup(false);
      } else {
        setMsgIsGroup(true);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (tab === "search") {
      searchInputRef.current.focus();
    }
  }, [tab]);

  let searchTimeout;

  const handleSearch = (e) => {
    const searchedTerm = e.target.value.toLowerCase();

    clearTimeout(searchTimeout);

    if (searchedTerm === "") {
      setSearchParam(searchedTerm);
      setTab("open");
      return;
    }

    searchTimeout = setTimeout(() => {
      setSearchParam(searchedTerm);
    }, 500);
  };

  const handleBack = () => {
    history.push("/");
  };

  const handleChangeTab = (e, newValue) => {
    setTab(newValue);
  };

  const handleChangeTabOpen = (e, newValue) => {
/*
    if (newValue === 'pending') {
      handleBack()
    }
*/
    setTabOpen(newValue);
  };

  const applyPanelStyle = (status) => {
    if (tabOpen !== status) {
      return { width: 0, height: 0 };
    }
  };

  const handleCloseOrOpenTicket = (ticket) => {
    setNewTicketModalOpen(false);
    if (ticket !== undefined && ticket.uuid !== undefined) {
      history.push(`/tickets/${ticket.uuid}`);
    }
  };

  const handleSelectedTags = (selecteds) => {
    const tags = selecteds.map((t) => t.id);
    setSelectedTags(tags);
  };

  const handleSelectedUsers = (selecteds) => {
    const users = selecteds.map((t) => t.id);
    setSelectedUsers(users);
  };

  return (
    <Paper elevation={0} square variant="outlined" className={classes.ticketsWrapper1}>
      <NewTicketModal
        modalOpen={newTicketModalOpen}
        onClose={(ticket) => {
          console.log("ticket", ticket);
          handleCloseOrOpenTicket(ticket);
        }}
      />
      <Paper elevation={0} square variant="outlined" className={classes.tabsHeader}>
        <Tabs
          value={tab}
          onChange={handleChangeTab}
          variant="fullWidth"
          indicatorColor="secondary"
          textColor="secondary"
          aria-label="icon label tabs example"
        >
          <Tab
            value={"open"}
            icon={<MoveToInboxIcon />}
            label={i18n.t("tickets.tabs.open.title")}
            classes={{ root: classes.tab, selected: classes.tabLabelActive }}
          />
          <Tab
            value={"closed"}
            icon={<CheckBoxIcon />}
            label={i18n.t("tickets.tabs.closed.title")}
            classes={{ root: classes.tab, selected: classes.tabLabelActive }}
          />
          <Tab
            value={"search"}
            icon={<SearchIcon />}
            label={i18n.t("tickets.tabs.search.title")}
            classes={{ root: classes.tab, selected: classes.tabLabelActive }}
          />
        </Tabs>
      </Paper>
      <Paper square elevation={0} className={classes.ticketOptionsBox}>
        {tab === "search" ? (
          <div className={classes.searchInputWrapper}>
            <SearchIcon className={classes.searchIcon} />
            <InputBase
              className={classes.searchInput}
              inputRef={searchInputRef}
              placeholder={i18n.t("tickets.search.placeholder")}
              type="search"
              onChange={handleSearch}
            />
          </div>
        ) : (
          <>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setNewTicketModalOpen(true)}
            >
              {i18n.t("ticketsManager.buttons.newTicket")}
            </Button>
            <Can
              role={user.profile}
              perform="tickets-manager:showall"
              yes={() => (
                <FormControlLabel
                  label={i18n.t("tickets.buttons.showAll")}
                  labelPlacement="start"
                  control={
                    <Switch
                      size="small"
                      checked={showAllTickets}
                      onChange={() =>
                        setShowAllTickets((prevState) => !prevState)
                      }
                      name="showAllTickets"
                      color="primary"
                    />
                  }
                />
              )}
            />
          </>
        )}
        <TicketsQueueSelect
          style={{ marginLeft: 6 }}
          selectedQueueIds={selectedQueueIds}
          userQueues={user?.queues}
          onChange={(values) => setSelectedQueueIds(values)}
        />
      </Paper>
      <TabPanel
        square
        value={tab}
        name="open"
        className={classes.ticketsWrapper}
      >
        <Tabs
          value={tabOpen}
          onChange={handleChangeTabOpen}
          variant="fullWidth"
          indicatorColor="secondary"
          textColor="secondary"
        >        

          {/* ATENDENDO */}
<Tab
  label={
    <>
<div style={{ display: "flex", alignItems: "center" }}>
  <Typography
    style={{
      fontSize: 12,
      fontWeight: 500,
    }}
  >
    {i18n.t("ticketsList.assignedHeader")}
  </Typography>
  <Badge
    style={{
      backgroundColor: "#319614",
      color: "#fff",
//      fontSize: "0.9em",
 //padding: "-1px 4px -1px 4px",
      display: "flex",

//      marginLeft: "0.4em"
    }}
    className={classes.opensBadge}
  >
    {openCount}
  </Badge>
</div>

    </>
  }
  value={"open"}
  name="open"
  classes={{ root: classes.tabPanelItem, selected: classes.tabLabelActive }}
/>


          {/* AGUARDANDO */}
<Tab
  label={
    <>
<div style={{ display: "flex", alignItems: "center" }}>
  <Typography
    style={{
      fontSize: 12,
      fontWeight: 500,
    }}
  >
    {i18n.t("ticketsList.pendingHeader")}
  </Typography>
  <Badge
    style={{
      backgroundColor: "#f44336",
      color: "#fff",
//      fontSize: "0.9em",
      display: "flex",

//      marginLeft: "0.4em"
    }}
    className={classes.pendingsBadge}
  >
    {pendingCount}
  </Badge>
</div>

    </>
  }
  value={"pending"}
  name="pending"
  classes={{ root: classes.tabPanelItem, selected: classes.tabLabelActive }}
/>          


          {/* GRUPOS */}
          {/* {!msgIsGroup && (
            <Tab
              label={
                <Grid container alignItems="center" justifyContent="center">
                  <Grid item>
                    <Badge
                      overlap="rectangular"
                      classes={{ badge: classes.badge }}
                      badgeContent={groupCount}
                      color="primary"
                    >
                      <UsersIcon
                        style={{
                          fontSize: 18,
                        }}
                      />
                    </Badge>
                  </Grid>
                  <Grid item>
                    <Typography
                      style={{
                        marginLeft: 8,
                        fontSize: 10,
                        fontWeight: 600,
                      }}
                    >
                      Grupos
                    </Typography>
                  </Grid>
                </Grid>
              }
              value={"groups"}
              classes={{ root: classes.tabPanelItem }}
            />
          )} */}
        </Tabs>

        <Paper square className={classes.ticketsWrapper}>
          <TicketsList
            status="open"
            isGroup="0"
            showAll={showAllTickets}
            selectedQueueIds={selectedQueueIds}
            updateCount={(val) => setOpenCount(val)}
            style={applyPanelStyle("open")}
          />
          <TicketsList
            status="pending"
            selectedQueueIds={selectedQueueIds}
            updateCount={(val) => setPendingCount(val)}
            style={applyPanelStyle("pending")}
            handleChangeTab={handleChangeTabOpen}
          />
          <TicketsList
            status="open"
            isGroup="1"
            selectedQueueIds={selectedQueueIds}
            updateCount={(val) => setGroupCount(val)}
            style={applyPanelStyle("groups")}
          />
        </Paper>
      </TabPanel>
      <TabPanel square value={tab} name="closed" className={classes.ticketsWrapper}>
        <TicketsList
          status="closed"
          showAll={true}
          selectedQueueIds={selectedQueueIds}
        />
      </TabPanel>
      <TabPanel square value={tab} name="search" className={classes.ticketsWrapper}>
        <TagsFilter onFiltered={handleSelectedTags} />
        {profile === "admin" && (
          <UsersFilter onFiltered={handleSelectedUsers} />
        )}
        <TicketsList
          searchParam={searchParam}
          showAll={true}
          tags={selectedTags}
          users={selectedUsers}
          selectedQueueIds={selectedQueueIds}
        />
      </TabPanel>
    </Paper>
  );
};

export default TicketsManagerTabs;