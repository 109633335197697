import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { i18n } from '../../translate/i18n';

toast.configure();

const useVersionChecker = () => {
  const [version, setVersion] = useState('');

  const checkVersion = async () => {
    try {
      const response = await fetch(`/version.json?timestamp=${new Date().getTime()}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();

      const storedTimestamp = localStorage.getItem('appTimestamp');
      const storedVersion = localStorage.getItem('appVersion');

      const clearCacheAndReload = async () => {
        if ('caches' in window) {
          const cacheNames = await caches.keys();
          await Promise.all(cacheNames.map(cacheName => caches.delete(cacheName)));
        }
        localStorage.setItem('appTimestamp', data.timestamp);
        localStorage.setItem('appVersion', data.version);
        window.location.reload(true);
      };

      if (!storedTimestamp || storedTimestamp !== data.timestamp) {
        let message;
        if (storedVersion !== data.version) {
          message = i18n.t('versionChecker.newVersionAvailable');
        } else {
          message = i18n.t('versionChecker.newUpdateAvailable');
        }

        toast.warning(message, {
          autoClose: false,
          onClick: async () => {
            await clearCacheAndReload();
          }
        });
      } else {
        localStorage.setItem('appTimestamp', data.timestamp);
        localStorage.setItem('appVersion', data.version);
      }

      setVersion(data.version);
    } catch (error) {
      console.error('Error checking version:', error);
      toast.error(i18n.t('versionChecker.errorCheckingVersion'));
    }
  };

  return { checkVersion, version };
};

export default useVersionChecker;
