import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from "react-router-dom";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import { Button, Divider, Typography, Hidden } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import { AuthContext } from "../../context/Auth/AuthContext";



const VcardPreview = ({ contact, numbers }) => {
  const history = useHistory();
  const { user } = useContext(AuthContext);

  const [selectedContact, setContact] = useState({
    name: "",
    number: 0,
    profilePicUrl: ""
  });

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          let contactObj = {
            name: contact,
            number: numbers.replace(/\D/g, ""),
            email: ""
          };
          const { data } = await api.post("/contacts/vcard", contactObj);
          setContact(data);
        } catch (err) {
          console.log(err);
          toastError(err);
        }
      };
      fetchContacts();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [contact, numbers]);

  const handleNewChat = async () => {
    try {
      // Verificar si existe un ticket vinculado a ese contacto en estado "closed"
      const { data: existingTickets } = await api.get(`/tickets?contactId=${selectedContact.id}&status=closed`);
      if (existingTickets.length > 0) {
        // Reabrir el primer ticket cerrado encontrado
        const { data: reopenedTicket } = await api.patch(`/tickets/${existingTickets[0].id}`, {
          status: "open"
        });
        history.push(`/tickets/${reopenedTicket.uuid}`);
      } else {
        // Crear un nuevo ticket si no existe ninguno en estado "closed"
        const { data: newTicket } = await api.post("/tickets", {
          contactId: selectedContact.id,
          userId: user.id,
          status: "open"
        });
        history.push(`/tickets/${newTicket.uuid}`);
      }
    } catch (err) {
      toastError(err);
    }
  };

  const [copied, setCopied] = useState(false);
  const handleCopyNumber = () => {
    navigator.clipboard.writeText(selectedContact.number.trim());
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  return (
    <>
      <div
        style={{
//          minWidth: "250px"
            width: "320px"
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={2} style={{ marginTop: "5px", marginLeft: "5px" }}>
            <Avatar src={selectedContact.profilePicUrl} />
          </Grid>
          <Grid item xs={9}>
            <Typography
              style={{ marginTop: "12px", marginLeft: "10px" }}
              variant="subtitle1"
              color="primary"
              gutterBottom
            >
              {selectedContact.name}
            </Typography>
            <Typography
              style={{ marginLeft: "8px", fontSize: "small" }}
              variant="subtitle1"
              color="primary"
              gutterBottom
            >
              {selectedContact.number}
            </Typography>            
          </Grid>
            <Grid item xs={12}>
            <Divider />
            <div style={{ position: "relative", display: "flex", justifyContent: "space-between" }}>
                <Button
                  size="small"
                  color="primary"
                  onClick={handleNewChat}
                  disabled={!selectedContact.number}
                >
                  {i18n.t("messagesList.vCard.message")}
                </Button>
                <a href={`tel:${selectedContact.number}`} style={{ textDecoration: 'none' }}>
                  <Button
                    size="small"
                    color="primary"
                    disabled={!selectedContact.number}
                  >
                    {i18n.t("messagesList.vCard.send")}
                  </Button>
                </a>  
                  
              <Button
                size="small"
                color="primary"
                onClick={handleCopyNumber}
                disabled={!selectedContact.number}
              >
                {i18n.t("messagesList.vCard.copy")}
              </Button>
              <Hidden only={["sm", "xs"]}>
                {copied && (
                  <span
                    style={{
                      position: "absolute",
                      top: "-39px",
                      left: "48%",
                    //  transform: "translateX(-50%)",
                    //  backgroundColor: "#fff",
                      padding: "4px 8px",
                      borderRadius: "4px",
                    //  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    {i18n.t("contactDrawer.copiedText")}
                  </span>
                )}
              </Hidden>                  
                  
                  
                
            </div>    
        </Grid>
        </Grid>
      </div>
    </>
  );
};

export default VcardPreview;