import React, { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { ptBR } from "@material-ui/core/locale";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import ColorModeContext from "./layout/themeContext";
import Routes from "./routes";

const queryClient = new QueryClient();

const App = () => {
  const [locale, setLocale] = useState();

  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const preferredTheme = window.localStorage.getItem("preferredTheme");
  const [mode, setMode] = useState(preferredTheme ? preferredTheme : prefersDarkMode ? "dark" : "light");

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  const theme = createTheme(
    {
      scrollbarStyles: {
        "&::-webkit-scrollbar": {
          width: "8px",
          height: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: mode === "light" ? "#d0d0d0" : "#555555",
          "&:hover": {
            backgroundColor: mode === "light" ? "#989898" : "#CCCCCC",
          },
        },
      },
      scrollbarMessages: {
        "&::-webkit-scrollbar": {
          width: "8px",
          height: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#e8e8e8",
          "&:hover": {
            backgroundColor: "#82828270",
          },
        },
      },
      palette: {
        primary: {
          main: "#0093A9",
        },
        secondary: {
          main: "#0093A9",
        },
        button: {
          main: "#B3B3B3",
        },
        type: mode,
        textPrimary: mode === "light" ? "#0093A9" : "#FFFFFF",
        borderPrimary: mode === "light" ? "#0093A9" : "#FFFFFF",
        dark: { main: mode === "light" ? "#333333" : "#F3F3F3" },
        light: { main: mode === "light" ? "#F3F3F3" : "#333333" },
        tabHeaderBackground: mode === "light" ? "#EEE" : "#333333",
        optionsBackground: mode === "light" ? "#FFFFFF" : "#3D3D3D",
        fancyBackground: mode === "light" ? "#fafafa" : "#333",
        total: mode === "light" ? "#fff" : "#2D2D2D",
        messageIconsInactive: mode === "light" ? "grey" : "#CCCCCC",
        messageIconsActive: mode === "light" ? "#0093A9" : "#0093A9",
        inputBackground: mode === "light" ? "#FFFFFF" : "#333",
        barraSuperior: mode === "light" ? "#0093A9" : "#666666",
        mensage: mode === "light" ? "#303030" : "#EEE",
        link: mode === "light" ? "#3b3aed" : "#6a94d8",      
        messageLeftBackground: mode === "light" ? "#E5E7E9" : "#303337",
        messageRightBackground: mode === "light" ? "#aeecce" : "#262C32",
        quotedContainerLeftBackground: mode === "light" ? "#d9d9d9" : "#3B3F42",
        quotedContainerRightBackground: mode === "light" ? "#B8F4DB" : "#39454F",
        quotedAdContainerLeft: mode === "light" ? "#d9d9d9" : "#3B3F42",
        messageCenterBackground: mode === "light" ? "#C6D9E3" : "#3D4B5A",
        quotedSideColorLeft: mode === "light" ? "#2D9161" : "#2D9161",
        quotedSideColorRight: mode === "light" ? "#0093A9" : "#0093A9",
        boxShadowColor: mode === "light" ? "#B3B3B3" : "#8F8F8F",
        paperComponent: mode === "light" ? "#303030" : "#FFFFFF",
        inputWrapperBorder: mode === "light" ? "#d9d9d9" : "#515151",
        inputSearchBorder: mode === "light" ? "#d9d9d9" : "#676767",
        bottomButton: mode === "light" ? "#999999" : "#E5E7E9",
        textContentItemDeleted: mode === "light" ? "#666666" : "#fafafa",
        prueba: mode === "light" ? "red" : "blue",
      },
      overrides: {
        MuiTabs: {
          indicator: {
            backgroundColor: "#0093A9",
          },
        },
      },
      mode,
    },
    locale
  );

  useEffect(() => {
    const i18nlocale = localStorage.getItem("i18nextLng");
    const browserLocale = i18nlocale ? i18nlocale.substring(0, 2) + i18nlocale.substring(3, 5) : null;

    if (browserLocale === "ptBR") {
      setLocale(ptBR);
    }
  }, []);

  useEffect(() => {
    window.localStorage.setItem("preferredTheme", mode);
  }, [mode]);

  return (
    <ColorModeContext.Provider value={{ colorMode }}>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <Routes />
        </QueryClientProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default App;
