import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Divider from "@material-ui/core/Divider";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
  listItem: {
    marginLeft: theme.spacing(-0.6),
  },
}));

const TicketsSkeleton = () => {
  const classes = useStyles();

  return (
    <>
      <ListItem dense className={classes.listItem}>
        <ListItemAvatar>
          <Skeleton
            animation="wave"
            variant="circle"
            width={46}
            height={46}
            style={{
              outline: `1px solid #757575`,
              outlineOffset: "3px",
              borderRadius: "50%",
            }}
          />
        </ListItemAvatar>
        <ListItemText
          primary={<Skeleton animation="wave" height={20} width={60} />}
          secondary={<Skeleton animation="wave" height={20} width={90} />}
        />
      </ListItem>
      <Divider variant="inset" />
      <ListItem dense className={classes.listItem}>
        <ListItemAvatar>
          <Skeleton
            animation="wave"
            variant="circle"
            width={46}
            height={46}
            style={{
              outline: `1px solid #757575`,
              outlineOffset: "3px",
              borderRadius: "50%",
            }}
          />
        </ListItemAvatar>
        <ListItemText
          primary={<Skeleton animation="wave" height={20} width={70} />}
          secondary={<Skeleton animation="wave" height={20} width={120} />}
        />
      </ListItem>
      <Divider variant="inset" />
      <ListItem dense className={classes.listItem}>
        <ListItemAvatar>
          <Skeleton
            animation="wave"
            variant="circle"
            width={46}
            height={46}
            style={{
              outline: `1px solid #757575`,
              outlineOffset: "3px",
              borderRadius: "50%",
            }}
          />
        </ListItemAvatar>
        <ListItemText
          primary={<Skeleton animation="wave" height={20} width={60} />}
          secondary={<Skeleton animation="wave" height={20} width={90} />}
        />
      </ListItem>
      <Divider variant="inset" />
    </>
  );
};

export default TicketsSkeleton;
