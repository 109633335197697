import React, { useEffect, useState, useCallback } from "react";
import { Chip, Paper, makeStyles, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import LabelIcon from '@material-ui/icons/Label';
import { isArray, isString } from "lodash";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: -3,
    backgroundColor: theme.palette.background.paper,
    '& label.Mui-focused': {
      color: "rgba(0, 0, 0, 0.12)",
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: "rgba(0, 0, 0, 0.12)",
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: "rgba(0, 0, 0, 0.12)",
        borderWidth: 0,
      },
      '&:hover fieldset': {
        borderColor: "rgba(0, 0, 0, 0.12)",
        borderWidth: 0,
      },
      '& fieldset': {
        borderColor: "rgba(0, 0, 0, 0.12)",
        borderWidth: 0,
      },
    },
  },
  customPaperComponent: {
    width: 312,
    backgroundColor: theme.palette.optionsBackground,
    color: theme.palette.paperComponent,
    fontSize: "14px",
    boxShadow: `0 1px 1px ${theme.palette.boxShadowColor}`,
    borderRadius: 4,
    marginLeft: "-7px",
  },  
}));

export function TagsContainer({ ticket }) {
  const classes = useStyles();
  const [placeholderText, setPlaceholderText] = useState(i18n.t("tags.placeholder1"));
  const [tags, setTags] = useState([]);
  const [selecteds, setSelecteds] = useState([]);

  useEffect(() => {
    loadTags();
  }, [ticket]);

  const loadTags = useCallback(async () => {
    try {
      const { data } = await api.get(`/tags/list`);
      setTags(data);
      setSelecteds(ticket.tags || []);
    } catch (err) {
      toastError(err);
    }
  }, [ticket]);

  const handleTagChange = async (value, reason) => {
    let optionsChanged = [];
    if (reason === 'create-option' && isArray(value)) {
      optionsChanged = await Promise.all(value.map(async item => {
        return isString(item) ? await createTag({ name: item }) : item;
      }));
    } else {
      optionsChanged = value;
    }

    setSelecteds(optionsChanged);
    syncTags(optionsChanged);
  };

  const createTag = async (data) => {
    try {
      const { data: responseData } = await api.post(`/tags`, data);
      return responseData;
    } catch (err) {
      toastError(err);
      return null;
    }
  };

  const syncTags = async (tagsToSync) => {
    try {
      await api.post(`/tags/sync`, { ticketId: ticket.id, tags: tagsToSync });
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <Autocomplete
      multiple
      size="small"
      options={tags}
      value={selecteds}
      freeSolo
      onChange={(event, value, reason) => handleTagChange(value, reason)}
      getOptionLabel={(option) => option.name}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <LabelIcon style={{ color: option.color || "#eee", fontSize: "18px" }} />
          &nbsp;
          <span style={{ fontSize: "14px" }}>{option.name}</span>
        </React.Fragment>
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant="outlined"
            style={{ backgroundColor: option.color || "#eee", textShadow: "0px 1px 1px rgba(0, 0, 0, 0.3)", color: "white" }}
            label={option.name}
            {...getTagProps({ index })}
            size="small"
          />
        ))
      }
      renderInput={(params) => (
        <TextField 
          {...params}
          variant="outlined"
          placeholder={placeholderText}
          onFocus={() => setPlaceholderText(i18n.t("tags.placeholder2"))}
          onBlur={() => setPlaceholderText(i18n.t("tags.placeholder1"))}
          classes={{ root: classes.root }}
        />
      )}
      PaperComponent={({ children }) => (
        <div className={classes.customPaperComponent}>{children}</div>
      )}
    />
  );
}
