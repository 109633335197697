import React from "react";
import { Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  thumbnail: { 
    width: "100px", 
    height: "100px", 
    borderTopLeftRadius: "5px",
    borderBottomLeftRadius: "5px",
    objectFit: "cover",
  },    
  messageText: {
    overflowWrap: "break-word",
    whiteSpace: "pre-wrap",
    color: theme.palette.background.message,
    fontSize: "14px",
    marginRight: "50px",
    marginLeft: "7px",
    marginTop: "7px",
    marginBottom: "-2px",
  },
  quotedAdContainerLeft: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
    textDecoration: "none",
    backgroundColor: theme.palette.quotedAdContainerLeft,
    borderRadius: "5px",

  },
  adContent: {
    display: "flex",
    flexDirection: "column",
    padding: "10px",
  },
  adTitle: {
    color: theme.palette.text.primary,
  },  
  domainText: {
    marginLeft: "8px",
  },  
}));

const AdPreview = ({ message, title, url, mediaType, sourceId, thumbnail }) => {
  const classes = useStyles();

  const handleAdClick = () => {
    window.open(url, "_blank");
  };

  const extractDomain = (url) => {
    const hostname = new URL(url).hostname;
    return hostname.startsWith('www.') ? hostname.slice(4) : hostname;
  };

  return (
    <>
      <div
        onClick={handleAdClick}
        className={classes.quotedAdContainerLeft}
      >
        <img
          src={thumbnail}
          alt={title}
          className={classes.thumbnail}
        />
        <div className={classes.adContent}>
          <Typography variant="subtitle1" color="primary" className={classes.adTitle}>
            {title}
          </Typography>
          <Typography variant="body2" color="textSecondary" className={classes.domainText}>
            {extractDomain(url)}
          </Typography>
        </div>
      </div>
      <Typography variant="body1" className={classes.messageText}>{message}</Typography>
    </>
  );
};

export default AdPreview;
