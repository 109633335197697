import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    inline: {
        width: '100%',
    },
    
    listItem: {
        marginLeft: "-5px",
        marginTop: "-12px",
        marginBottom: "-14px",
       width: "calc(100% + 16px)",
    },
    
    closeIcon: {
        fontSize: 20,
    },    
}));

export default function ContactNotesDialogListItem (props) {
    const { note, deleteItem } = props;
    const classes = useStyles();

    const handleDelete = (item) => {
        deleteItem(item);
    }

    return (
        <ListItem alignItems="flex-start" className={classes.listItem}>
            <ListItemText
                primary={
                    <>
                        <Typography
                        //    component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textPrimary"
                        >
                          ⭕ {note.note}
                        </Typography>
                    </>
                }
                secondary={
                    <>
                        {note.user.name}, {moment(note.createdAt).format('DD/MM/YY HH:mm')}
                    </>
                }
            />
            <ListItemSecondaryAction>
                <IconButton
                variant="contained"
                size="small"
                onClick={() => handleDelete(note)}

                >
                    <CloseIcon className={classes.closeIcon}/>
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    )   
}

ContactNotesDialogListItem.propTypes = {
    note: PropTypes.object.isRequired,
    deleteItem: PropTypes.func.isRequired
}