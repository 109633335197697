import React, { useEffect, useState } from "react";
import { Box, Chip, TextField } from "@material-ui/core";
import LabelIcon from '@material-ui/icons/Label';
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
    
  textField: {
    marginLeft: -3,
    marginTop: -1,
    marginRight: -2,
    marginBottom: -1,
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderWidth: 0,
      },
      '&:hover fieldset': {
        borderWidth: 0,
      },
      '& fieldset': {
        borderWidth: 0,
      },
      '&.Mui-focused:not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
        borderWidth: 0,
      },
      '&:hover:not(.Mui-disabled):not(.Mui-focused) .MuiOutlinedInput-notchedOutline': {
        borderWidth: 0,
      },
    },
  },
  
  textFieldWrapper: {
    marginLeft: "-2px",
    marginRight: "0",
    width: "calc(100% + 5px)",
    flex: 1,
    display: "flex",
    border: `1px solid ${theme.palette.inputSearchBorder}`,
    borderRadius: 4,
    padding: 0,
  },
  labelOutlinedIcon: {
    color: "grey",
    marginLeft: 7,
    marginRight: 0,
    alignSelf: "center",
    fontSize: 23,    
  },
  customPaperComponent: {
    backgroundColor: theme.palette.optionsBackground,
    color: theme.palette.paperComponent,
    fontSize: "14px",
//    border: `1px solid ${theme.palette.inputSearchBorder}`,
    boxShadow: `0 1px 1px ${theme.palette.boxShadowColor}`,
    borderRadius: 4,
    marginLeft: "-28px",
//    marginRight: "1px",
//    paddingLeft: "10px",
//    paddingRight: "10px",
  },
}));

export function TagsFilter({ onFiltered, initialTags }) {
  const classes = useStyles();
  const [tags, setTags] = useState([]);
  const [selecteds, setSelecteds] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      await loadTags();
    };
    fetchData();
  }, []);

  useEffect(() => {
    setSelecteds([]);
    if (
      Array.isArray(initialTags) &&
      Array.isArray(tags) &&
      tags.length > 0
    ) {
      onChange(initialTags);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialTags, tags]);

  const loadTags = async () => {
    try {
      const { data } = await api.get("/tags/list");
      const tagList = data.map((u) => ({ id: u.id, name: u.name, color: u.color }));
      setTags(tagList);
    } catch (err) {
      toastError(err);
    }
  };

  const onChange = async (value) => {
    setSelecteds(value);
    onFiltered(value);
  };

  return (
    <Box style={{ padding: "0px 10px 10px" }}>
      <Autocomplete
        multiple
        size="small"
        options={tags}
        value={selecteds}
        onChange={(e, v, r) => onChange(v)}
        getOptionLabel={(option) => option.name}
    renderOption={(option, { selected }) => (
<React.Fragment>
  <LabelIcon
    style={{
      color: option.color || "#eee",
      fontSize: "18px",
    }}
  />
  &nbsp;
  <span style={{ fontSize: "14px" }}>{option.name}</span>
</React.Fragment>
    )}        
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              key={option.id} // Clave única "key" para cada chip
              variant="outlined"
              style={{
                backgroundColor: option.color || "#eee",
                textShadow: "0px 1px 1px rgba(0, 0, 0, 0.2)",
                border: "0px solid rgba(0, 0, 0, 0.2)",
                color: "white",
                //    boxShadow: "0 1px 2px rgba(0, 0, 0, 0.2)" 
              }}
              label={option.name}
              {...getTagProps({ index })}
              size="small"
            />
          ))
        }
        renderInput={(params) => (
          <div className={classes.textFieldWrapper}>
            <LabelOutlinedIcon className={classes.labelOutlinedIcon} />
          <TextField
            className={classes.textField}
            {...params}
            variant="outlined"
            placeholder={i18n.t("filters.tags.placeholder")}
          />
          </div>
        )}
        PaperComponent={({ children }) => (
          <div className={classes.customPaperComponent}>
            {children}
          </div>
        )}
      />
    </Box>
  );
}
