const messages = {
  pt: {
    translations: {
        required: "Obligatorio",
        name: "Nombre",
        email: "Correo electrónico",
        invalidMail: "Dirección de correo no válida",
        nothing: "Ningún registro",
        operNotif: "Abrir notificaciones",
        password: "Contraseña",
        download: "Descargar",
        accept: "Aceptar",
        save: "Guardar",
        add: "Agregar",
        edit: "Editar",
        cancel: "Cancelar",
        tooLong: "Demasiado largo",
        tooShort: "Demasiado corto",
        typeError: "Debe ser un número",
        tooLongNumber: "Debe ser un número menor",
        tooShortNumber: "Debe ser un número mayor",
        toastError: "Ocurrió un error!",
        
        signup: {
          title: "Formulario de Registro",
          toasts: {
            success: "¡Su cuenta ha sido creada satisfactoriamente! ¡Ahora inicia sesión!",
            fail: "Error al crear usuario. Verifique los datos ingresados.",
          },
          form: {
            name: "Nombre",
            email: "Correo electrónico",
            password: "Contraseña",
            company: "Nombre de la empresa",
            phone: "Número Whatsapp",
            plan: "Plan",
            detailsPlan: {
              users: "Usuarios",
              connections: "Conexiones",
              queues: "Filas",
              price: "Precio",
            },
          },
          buttons: {
            submit: "Regístrate",
            login: "¿Ya tienes una cuenta? ¡Inicia sesión!",
          },
        },
        
      login: {
        title: "Inicio de Sesión",
        form: {
          email: "Correo electrónico",
          password: "Contraseña",
        },
        buttons: {
          submit: "Ingresar",
          register: "¿No tienes cuenta? ¡Regístrate!",
        },
      },
			companies: {
				title: "Registro de Empresas",
				form: {
					name: "Nombre de la empresa",
					plan: "Plan",
					token: "Token",
					submit: "Crear",
					success: "Empresa creada con éxito!",
				},
			},
      auth: {
        toasts: {
          success: "¡Inicio de sesión exitoso!",
        },
		dueDate: {
			expiration: "Su suscripción expira en",
			days: "dias!",
			day: "dia!",
			expirationToday: "Su suscripción expira hoy!",
			expiresToday: "Su suscripción expira hoy!. Debe renovarlo hoy mismo para evitar la suspensión de su servicio.",
			expiresTomorrow: "Su suscripción expira mañana!. Debe renovarlo para evitar la suspensión de su servicio.",
            avoidSuspension: "Evite la suspensión de su servicio renovándola antes del",
            expiredSubscription:"Su suscripción venció el",
            contactSupport: "Entre en contacto con el soporte para más información.",
		},
		token: "Token",
	  },
      dashboard: {
        charts: {
          perDay: {
            title: "Atendidos hoy: ",
          },
        },
        messages: {
          inAttendance: {
            title: "En servicio"
          },
          waiting: {
            title: "Esperando"
          },
          closed: {
            title: "Finalizado"
          }
        }
      },
      connections: {
        title: "Conexiones",
        toasts: {
          deleted: "Conexión eliminada satisfactoriamente!",
        },
        confirmationModal: {
          deleteTitle: "Eliminar",
          deleteMessage: "¿Estás seguro? Este proceso no puede ser revertido.",
          disconnectTitle: "Desconectar",
          disconnectMessage: "Estás seguro? Deberá volver a leer el código QR",
        },
        buttons: {
          add: "Agregar Conexión",
          disconnect: "Desconectar",
          restart: "Reiniciar conexiones",
          restarting: "Aguarde... Sus conexiones serán reiniciadas!",
          support: "Contactar al soporte",
          tryAgain: "Inténtalo de nuevo",
          qrcode: "CÓDIGO QR",
          newQr: "Nuevo CÓDIGO QR",
          connecting: "Conectando",
        },
        toolTips: {
          disconnected: {
            title: "No se pudo iniciar la sesión",
            content:
              "Asegúrese de que su teléfono celular esté conectado a Internet y vuelva a intentarlo o solicite un nuevo CÓDIGO QR",
          },
          qrcode: {
            title: "Esperando la lectura del código QR",
            content: "Haga clic en el botón 'CÓDIGO QR' y lea el código con su teléfono celular para iniciar la sesión",
          },
          connected: {
            title: "Conexión establecida",
          },
          timeout: {
            title: "Se perdió la conexión con el teléfono celular",
            content: "Asegúrese de que su celular esté conectado a Internet y que WhatsApp esté abierto, o haga clic en el botón 'Desconectar' para obtener un nuevo código QR",
          },
        },
        table: {
          channel: "Canal",
          id: "Id",
          name: "Nombre",
          status: "Estado",
          session: "Sesión",
          lastUpdate: "Última Actualización",
          number: "Número",
          default: "Por Defecto",
          actions: "Acciones",
        },
      },
      whatsappModal: {
        title: {
          add: "Agregar Conexión",
          edit: "Editar Conexión",
        },
        form: {
          name: "Nombre",
          default: "Por Defecto",
          maxUseBotQueues: "Número máximo de veces que se enviará el chatbot",
          expiresTicket: "Finalizar chats abiertos después de x horas",
          outOfHoursMessage: "Mensaje fuera de horario",
          greetingMessage: "Mensaje de bienvenida",
          complationMessage: "Mensaje de finalización",
          token: "Token para API",
        },
        buttons: {
          okAdd: "Agregar",
          okEdit: "Guardar",
          cancel: "Cancelar",
        },
        success: "Conexión guardado satisfactoriamente.",
      },
      qrCode: {
        message: "Lée el código para iniciar sesión.",
        waiting: "Esperando Código QR",
      },
      contacts: {
        title: "Contactos",
        toasts: {
          deleted: "¡Contacto eliminado satisfactoriamente!",
          contactBlocked: "Contacto bloqueado!",
		  contactUnlocked: "Contacto desbloqueado",
        },
        searchPlaceholder: "Buscar...",
        confirmationModal: {
          deleteTitle: "Eliminar",
          blockContact: "Bloquear el contacto",
          unblockContact: "Desbloquear el contacto",
          importTitlte: "Importar contactos",
          deleteMessage: "¿Estás seguro que deseas eliminar este contacto? Todos los chats relacionados se perderán.",
          importMessage: "¿Quieres importar todos los contactos desde tu teléfono?",
          blockingContact: "Estás seguro que quieres bloquear este contato?",
		  unBlockingContact: "Estás seguro que quieres desbloquear este contato?",
		  importChatsTitle: "Importar Mensajes",
		  importChats: "Deseas importar todos los mensajes del teléfono?",
        },
        buttons: {
          importExport: "Importar / Exportar",
          import: "Importar Contactos",
          add: "Agregar Contacto",
          export: "Exportar Contacto",
          importPhoneBook: "Importar Agenda",
          importExcel: "Importar Excel",
        },
        table: {
          name: "Nombre",
          whatsapp: "Número",
          email: "Correo Electrónico",
          lastMessege: "Último Mensaje",
		  status: "Estado",
          actions: "Acciones",
        },
      },
			forwardMessage: {
				text: "Reenviar",
			},
			forwardMessageModal: {
				title: "Reenviar mensaje a",
				buttons: {
					ok: "Reenviar",
				}
			},
      contactModal: {
        title: {
          add: "Agregar contacto",
          edit: "Editar contacto",
        },
        form: {
          mainInfo: "Detalles del contacto",
          extraInfo: "Información adicional",
          name: "Nombre",
          number: "Número de Contacto",
          email: "Correo Electrónico",
          extraName: "Nombre del Campo",
          extraValue: "Valor",
          enableChatBot: "Desabilitar chatbot para este contacto",
          acceptAudioMessage: "Recibir audio de este contacto",
        },
        buttons: {
          addExtraInfo: "Agregar información",
          okAdd: "Agregar",
          okEdit: "Guardar",
          cancel: "Cancelar",
        },
        success: "Contacto guardado exitosamente.",
      },
      quickAnswersModal: {
        title: {
          add: "Agregar respuesta rápida",
          edit: "Editar respuesta rápida",
        },
        form: {
          shortcut: "Atajo",
          message: "Respuesta rápida",
        },
        buttons: {
          okAdd: "Agregar",
          okEdit: "Guardar",
          cancel: "Cancelar",
        },
        success: "Respuesta rápida guardada exitosamente.",
      },
      queueModal: {
        success: "Área guardada exitosamente.",
        deleted: "¿Estás seguro que deseas eliminar esta opción?",
        areaData: "Datos del área",
        attentionSchedule: "Horarios de Atención",
        title: {
          add: "Agregar área",
          edit: "Editar área",
        },        
        options: {
          title: "Opciones",
        },
        form: {
          name: "Nombre",
          color: "Color",
          greetingMessage: "Mensaje de saludo",
          required: "Requerido",
          contactRequired: "Contacto Requerido",
          complationMessage: "Mensaje de finalización",
          outOfHoursMessage: "Mensaje fuera de horario",
          token: "Token API",
        },
        buttons: {
          okAdd: "Agregar",
          okEdit: "Guardar",
          cancel: "Cancelar",
        },
        chatBot: {
          title: "Chatbot",
          options: "Agregar Opciones",
          help: "Agregue las opciones para crear un chatbot",
          content: "Si solo hay una opción, se elegirá automáticamente, lo que hará que el bot responda con el mensaje de opción y continúe.",
          agent: "Área",
          msg: "Mensaje",
          help2: "Se requiere un mensaje para continuar al siguiente nivel",
          content2: "Si el mensaje no está configurado, el bot no procederá",
          saved: "Configuración guardada exitosamente",
          deletConfirm: "¿Estás seguro? Todas las opciones internas también se eliminarán",
          deleted: "Bot eliminado correctamente",
        },        
      },
      missedCall: {
          label: "Llamada perdida a las",
          message: "*Mensaje automático:* Las llamadas de voz no se encuentran disponibles en este momento para este WhatsApp, envíe un mensaje. Gracias"
        },
      actionIcon: {
          acept: "Aceptar",
          return: "Devolver",
          reopen: "Reabrir",
        },        
      textLength: {
          long: "¡Demasiado largo!",
          short: "¡Demasiado corto!",
          email: "Correo no válido",
        },        
      userModal: {
        title: {
          add: "Agregar usuario",
          edit: "Editar usuario",
        },
        form: {
          name: "Nombre",
          email: "Correo electrónico",
          password: "Contraseña",
          profile: "Perfil",
          whatsapp: "Conexión estándar",
          farewellMessage: "Mensaje de despedida",
          startWork: "Iniciar trabajo",
          endWork: "Finalizar trabajo",
        },
        buttons: {
          okAdd: "Agregar",
          okEdit: "Guardar",
          cancel: "Cancelar",
        },
        success: "Usuario guardado satisfactoriamente.",
      },
			companyModal: {
				title: {
					add: "Agregar empresa",
					edit: "Editar empresa",
				},
				form: {
					name: "Nombre",
					email: "Email",
					passwordDefault: "Contraseña",
					numberAttendants: "Usuarios",
					numberConections: "Conexiones",
				},
				buttons: {
					okAdd: "Agregar",
					okEdit: "Guardar",
					cancel: "Cancelar",
				},
				success: "Empresa creada con éxito!",
			},

			ratingStatus: {
			    rating: "Calificación",
			    qtd: "Qtd. Conversación",
			    tm: "TM. Conversación",
			    userStatus: "Estado Actual",
			    satisfaction: {
			        1: "1 - Insatisfecho",
			        2: "2 - Satisfecho",
			        3: "3 - Muy satisfecho",
			    },
			},
			ratingModal: {
				title: {
					add: "Agregar mi de calificación",
					edit: "Editar mi de calificación",
				},
				buttons: {
					okAdd: "Guardar",
					okEdit: "Editar",
					cancel: "Cancelar",
					options: "Agregar opción",
				},
				form: {
					name: "Nombre",
					message: "Mensaje de calificación",
					options: "Opciones de calificación",
					extraName: "Nombre del campo",
					extraValue: "Valor",
				},
				success: "Calificación creada con éxito!",
			},
      chat: {
        noTicketMessage: "Seleccione una conversación",
      },
      ticketsManager: {
        buttons: {
          newTicket: "Nuevo",
        },
      },
      ticketsQueueSelect: {
        placeholder: "Áreas",
        all: "Todas",
      },
      tickets: {
        toasts: {
          deleted: "Conversación eliminada",
        },
        notification: {
          message: "Mensaje de",
        },
        tabs: {
          open: { title: "Bandeja" },
          closed: { title: "Finalizados" },
          search: { title: "Buscar" },
        },
        search: {
          placeholder: "Buscar",
        },
        buttons: {
          showAll: "Todos",
        },
      },
      transferTicketModal: {
        title: "Transferir Conversación",
        fieldLabel: "Escriba para buscar usuarios",
        fieldQueueLabel: "Transferir al área",
        fieldConnectionLabel: "Transferir a la conexión",
        fieldQueuePlaceholder: "Seleccione un área",
        fieldConnectionPlaceholder: "Seleccione una conexión",
        noOptions: "No se encontraron usuarios con ese nombre",
        buttons: {
          ok: "Transferir",
          cancel: "Cancelar",
        },
      },
      ticketsList: {
        pendingHeader: "En espera",
        assignedHeader: "En proceso",
        connectionTitle: "Conexión que se está utilizando actualmente.",
        noTicketsMessage: "Ninguna conversación con este estado o término de búsqueda",
        buttons: {
          accept: "Acceptar",
          reopen: "Reabrir",
          closed: "Cerrar",
        },
      },
      newTicketModal: {
        title: "Iniciar Conversación",
        conctactsLabel: "Escribe para buscar un contacto",
        queuesLabel: "Seleccione un área",
        add: "Agregar",
        buttons: {
          ok: "Iniciar",
          cancel: "Cancelar",
        },
      },
      mainDrawer: {
        listItems: {
          dashboard: "Dashboard",
          connections: "Conexiones",
          tickets: "Conversaciones",
          contacts: "Contactos",
          quickMessages: "Respuestas rápidas",
          queues: "Áreas & Chatbot",
          schedules: "Agendamientos",
          tags: "Etiquetas",
          chats: "Chat Interno",
          administration: "Administración",
          menu: "Menú",
          companies: "Empresas",
          users: "Usuarios",
          help: "Ayuda",
          financeiro: "Financiero",
          listagem: "Listado",
          contactlist: "Lista de contactos",
          campaignsconfig: "Configuraciones",
          settings: "Configuraciones",
          helps: "Ayuda",
          messagesAPI: "API",
          annoucements: "Informaciones",
          ratings: "Calificaciones",
          version: "Versión",
          campaigns: {
            title: "Campañas",
            list: "Lista de Campañas",
            contactsList:"Lista de Contactos",
            settings: "Configuraciones",
          },
        },
        appBar: {
          user: {
            profile: "Perfil",
            logout: "Cerrar Sesión",
          },
        },

          refresh: "Atualizar",

        },

			languages: {
				undefined: "Idioma",
				"pt-BR": "Português",
				es: "Español",
				en: "English",
				tr: "Türkçe",
			},
			messagesAPI: {
				title: "API",
				textMessage: {
					number: "Número",
					body: "Mensaje",
					token: "Token Adicionado",
				},
				mediaMessage: {
					number: "Número",
					body: "Nombre del archivo",
					media: "Archivo",
					token: "Token Adicionado",
				},
			},
      notifications: {
        noTickets: "Sin notificaciones.",
      },
        help: {
          title: "Centro de ayuda",
          conection: {
            title: "Creando la conexión",
            description: "Conecte su CRM a sus canales de chats",
            video: 'A9iKegEVz4s',
          },          
          attendance: {
            title: "Primer atendimiento",
            description: "Mire cómo es fácil realizar la atención al cliente con este CRM",
            video: 'bajfI3YXrtU',
          },
          queues: {
            title: "Áreas o departamentos",
            description: "Cree las áreas o departamentos para que el cliente direccione su mensaje al departamento que le va a atender",
            video: 'pgyqaQhe1TQ'
          },
        },
			contactLists: {
				title: "Listas de Contatos",
				table: {
					name: "Nombre",
					contacts: "Contactos",
					actions: "Acciones",
				},
				buttons: {
					add: "Nueva Lista",
				},
				dialog: {
					name: "Nombre",
					company: "Empresa",
					okEdit: "Editar",
					okAdd: "Agregar",
					add: "Agregar",
					edit: "Editar",
					cancel: "Cancelar",
				},
				confirmationModal: {
					deleteTitle: "Eliminar",
					deleteMessage: "¿Estás seguro que desea eliminar esta lista?",
				},
				toasts: {
					deleted: "Registro eliminado",
				},
			},
			contactListItems: {
				title: "Contactos",
				searchPlaceholder: "Buscar Contacto",
				buttons: {
					add: "Nuevo Contacto",
					lists: "Listas",
					import: "Importar",
				},
				dialog: {
					name: "Nombre",
					number: "Número",
					whatsapp: "Whatsapp",
					email: "E-mail",
					okEdit: "Editar",
					okAdd: "Agregar",
					add: "Agregar",
					edit: "Editar",
					cancel: "Cancelar",
				},
				/*
				table: {
					name: "Nombre",
					number: "Número",
					whatsapp: "Whatsapp",
					email: "E-mail",
					actions: "Acciones",
				},
				confirmationModal: {
					deleteTitle: "Eliminar",
					deleteMessage: "Esta a punto de eliminar este contacto, esta seguro?",
					importMessage: "Desear importar los contactos?",
					importTitlte: "Importar",

				},
				toasts: {
					deleted: "Registro eliminado",
				},
				*/
			},
			campaigns: {
				title: "Campañas",
				searchPlaceholder: "Buscar Campaña",
				buttons: {
					add: "Nueva Campaña",
					contactLists: "Listas de Contatos",
				},
				table: {
					name: "Nombre",
					whatsapp: "Conexión",
					contactList: "Lista de Contatos",
					status: "Estado",
					scheduledAt: "Agendamentos",
					completedAt: "Realizado",
					confirmation: "Confirmación",
					actions: "Acciones",
				},
				dialog: {
					new: "Nueva Campaña",
					update: "Editar Campaña",
					readonly: "Visualizar Campaña",
					form: {
						name: "Nombre",
						message1: "Mensaje 1",
						message2: "Mensaje 2",
						message3: "Mensaje 3",
						message4: "Mensaje 4",
						message5: "Mensaje 5",
						confirmationMessage1: "Mensaje de Confirmación 1",
						confirmationMessage2: "Mensaje de Confirmación 2",
						confirmationMessage3: "Mensaje de Confirmación 3",
						confirmationMessage4: "Mensaje de Confirmación 4",
						confirmationMessage5: "Mensaje de Confirmación 5",
						messagePlaceholder: "Contenido del Mensaje",
						whatsapp: "Conexión",
						status: "Estado",
						scheduledAt: "Agendamientos",
						confirmation: "Confirmación",
						contactList: "Lista de Contatos",
					},
					buttons: {
						add: "Agregar",
						edit: "Actualizar",
						okadd: "Ok",
						cancel: "Cancelar Campaña",
						restart: "Reiniciar Campaña",
						close: "Cerrar",
						attach: "Adjuntar Archivo",
					},
				},
				confirmationModal: {
					deleteTitle: "Eliminar",
					deleteMessage: "Esta accion no se puede deshacer, esta seguro?",
				},
				toasts: {
					success: "Operación exitosa",
					cancel: "Campaña cancelada",
					restart: "Campaña reiniciada",
					deleted: "Registro eliminado",
				},
			},
			announcements: {
				title: "Informativos",
				searchPlaceholder: "Buscar",
				buttons: {
					add: "Nuevo Informativo",
					contactLists: "Listas de Informativos",
				},
				table: {
					priority: "Prioridad",
					title: "Titulo",
					text: "Texto",
					mediaName: "Archivo",
					status: "Estado",
					actions: "Acciones",
				},
				dialog: {
					edit: "Editar Informativo",
					add: "Nuevo Informativo",
					update: "Editar Informativo",
					readonly: "Solo visualizar",
					form: {
						priority: "Prioridad",
						title: "Titulo",
						text: "Texto",
						mediaPath: "Archivo",
						status: "Estado",
					},
					buttons: {
						add: "Agregar",
						edit: "Atualizar",
						okadd: "Ok",
						cancel: "Cancelar",
						close: "Cerrar",
						attach: "Adjuntar Archivo",
					},
				},
				confirmationModal: {
					deleteTitle: "Eliminar",
					deleteMessage: "Esta accion no se puede deshacer, esta seguro?",
				},
				toasts: {
					success: "Operación exitosa",
					deleted: "Registro eliminado",
				},
			},
			campaignsConfig: {
				title: "Configuración de Campañas",
			},

      users: {
        title: "Usuarios",
        filter: "Filtrar por usuario",
        table: {
          name: "Nombre",
          email: "Correo Electrónico",
          profile: "Perfil",
          whatsapp: "Conexión estándar",
          status: "Estado",
          startWork: "Inicio de trabajo",
          endWork: "Fin de trabajo",
          actions: "Acciones",
        },
        buttons: {
          add: "Agregar usuario",
        },
        toasts: {
          deleted: "Usuario eliminado satisfactoriamente.",
        },
        confirmationModal: {
          deleteTitle: "Eliminar",
          deleteMessage: "Toda la información del usuario se perderá. Sus chats abiertos quedarán como mensajes en espera.",
        },
      },
			compaies: {
				title: "Empresas",
				table: {
					status: "Activo",
					name: "Nombre",
					email: "Email",
					numberAttendants: "Convenciones",
					numberConections: "Conexiones",
					value: "Valor",
					namePlan: "Nombre Plan",
					numberQueues: "Filas",
					useCampaigns: "Campañas",
					useExternalApi: "Rest API",
					useFacebook: "Facebook",
					useInstagram: "Instagram",
					useWhatsapp: "Whatsapp",
					useInternalChat: "Chat Interno",
					useSchedules: "Agendamientos",
					createdAt: "Creado en",
					dueDate: "Vencimiento",
					lastLogin: "Ult. Login",
					actions: "Acciones",
				},
				buttons: {
					add: "Agregar Empresa",
				},
				toasts: {
					deleted: "Empresa eliminado con éxito",
				},
				confirmationModal: {
					deleteTitle: "Eliminar",
					deleteMessage: "¿Estás seguro? ¡Esta acción no se puede revertir!. Se perderán todos los datos de la empresa.",
				},
			},
			helps: {
				title: "Central de Ayuda",
			},
      schedules: {
        title: "Agendamientos",
        contactPlaceholder: "Contacto",
        confirmationModal: {
          deleteTitle:
            "Estás seguro de eliminar este agendamiento?",
          deleteMessage: "Exta acción no puede ser revertida.",
        },
        table: {
          contact: "Contacto",
          body: "Mensaje",
          sendAt: "Fecha de Agendamiento",
          sentAt: "Fecha de Envío",
          status: "Estado",
          actions: "Acciones",
        },
        buttons: {
          add: "Nuevo Agendamiento",
        },
        toasts: {
          deleted: "Agendamiento eliminado con éxito.",
        }
      },
      scheduleModal: {
          title: "Mensaje Programado",
        validationMessage: "Mensaje muy corto",
        required: "Obligatorio",
        
        status: {
          pending: "Pendiente",
          sent: "Enviado!",
          scheduled: "Programado",
          error: "Fallido",
        },
        form: {
          body: "Mensaje",
          contact: "Contacto",
          sendAt: "Fecha de Agendamiento",
          sentAt: "Fecha de Envío"
        },
        buttons: {
          okAdd: "Agregar",
          okEdit: "Guardar",
          cancel: "Cancelar",
        },
        success: "Agendamiento registrado con éxito.",
      },
      
      tagModal: {
        title: {
          add: "Nueva Etiqueta",
          edit: "Editar Etiqueta",
        },
        form: {
          name: "Nombre",
          color: "Color"
        },
        buttons: {
          okAdd: "Guardar",
          okEdit: "Guardar",
          cancel: "Cancelar",
        },
        success: "Etiqueta guardado con éxito!",
      },      
      queues: {
        title: "Áreas",
        table: {
          name: "Nombre",
          color: "Color",
          greeting: "Mensaje de saludo",
          actions: "Acciones",
        },
        toasts: {
          deleted: "Área eliminada satisfactoriamente.",
        },
        buttons: {
          add: "Agregar Área",
        },
        confirmationModal: {
          savedSuccess: "Área guardada con éxito",  
          deleteTitle: "Eliminar",
          deleteMessage: "¿Estás seguro? ¡Esta acción no se puede revertir! Los chats en esa área seguirán existiendo, pero ya no tendrán ningún área asignada.",
        },
      },
      queueSelect: {
        inputLabel: "Áreas",
      },
      quickAnswers: {
        title: "Respuestas rápidas",
        table: {
          shortcut: "Atajo",
          message: "Respuesta rápida",
          actions: "Acciones",
        },
        buttons: {
          add: "Agregar respuesta rápida",
        },
        modal: {
          title: "Respuesta Rápida",
          placeholder: {
			shortcode: "Atajo",
			message: "Respuesta",
		  },
        },
        toasts: {
          created: "Respuesta rápida creada exitsamente",
          updated: "Respuesta rápida actualizada exitosamente",
          deleted: "Respuesta rápida eliminada exitosamente",
        },
        searchPlaceholder: "Buscar ...",
        confirmationModal: {
          deleteTitle:
            "Eliminar Respuesta Rápida",
          deleteMessage: "¿Estás seguro que deseas eliminar?",
        },
      },
      
      filters: {
        tags: {
          placeholder: "Filtrar por etiquetas",
        },
          users: {
          placeholder: "Filtrar por usuarios",
        },
        buttons: {
          add: "Agregar usuario",
        },
        toasts: {
          deleted: "Usuario eliminado satisfactoriamente.",
        },
        confirmationModal: {
          deleteTitle: "Eliminar",
          deleteMessage: "Toda la información del usuario se perderá. Los chats abiertos de los usuarios se moverán a la cola.",
        },
      },
      tags: {
        title: "Etiquetas",
        placeholder1: "Agregar etiqueta",
        placeholder2: "Seleccionar",
        filter: "Filtrar por etiquetas",
        confirmationModal: {
          deleteTitle:
            "Estás seguro de eliminar esta etiqueta?",
          deleteMessage: "Esta acción no se puedrá deshacer.",
        },
        table: {
          name: "Nombre",
          color: "Color",
          tickets: "Contactos Etiquetados",
          actions: "Acciones",
        },
        buttons: {
          add: "Nueva Etiqueta",
        },
        toasts: {
          deleted: "Etiqueta eliminada con éxito.",
        }
      },
			ratings: {
				title: "Clasificaciones",
				table: {
					name: "Nombre",
					contacts: "Contactos",
					actions: "Acciones",
				},
				toasts: {
					deleted: "Calificación eliminada con éxito.",
					deletedAll: "Todas las calificaciones eliminadas con éxito.",
				},
				buttons: {
					add: "Agregar Calificación",
					deleteAll: "Boorar Todas",
				},
				confirmationModal: {
					deleteTitle: "Boorar",
					deleteAllTitle: "Borar Todas",
					deleteMessage: "Ten cuidado, esta acción no se puede deshacer. ¿Estás seguro?",
					deleteAllMessage: "¿Está seguro de que desea eliminar todas las calificaciones?",
				},
			},
      settings: {
        success: "Configuración guardada satisfactoriamente.",
        title: "Configuración",
        settings: {
          userCreation: {
            name: "Creación de usuarios",
            note: "Si se habilita cualquier persona se podrá registrar como usuario del sistema",
            options: {
              enabled: "Inhabilitado",
              disabled: "Habilitado",
            },
          },
          call: {
            name: "Responder llamadas perdidas",
            note: "Si está habilitado responderá con un mensaje las llamadas perdidas informando que las llamadas no están disponibles",
            message: "Llamada perdida a las",            
            options: {
              enabled: "Inhabilitado",
              disabled: "Habilitado",
            },
          },
          chatBot: {
            type: "Tipo de chatbot",
            note: "Selecciona uno de los tres tipos de chatbots para usar",
            options: {
              text: "Texto",
              button: "Botón",
              list: "Lista",
            },
          },          
          CheckMsgIsGroup: {
            name: "Recibir mensajes de grupos",
            note: "Si está habilitado se ignorarán los mensajes de grupos",
            options: {
              enabled: "Inhabilitado",
              disabled: "Habilitado",
            },
          },
        },
      },
      messagesList: {
        header: {
          assignedTo: "Asignado a:",
          closed: "Conversación Finalizada",
          started: "Conversación Iniciada",
          queue: "Sector:",
          queueno: "Sector: Ninguno",
					dialogRatingTitle: "¿Cómo quieres finalizar la conversación?",
					dialogClosingTitle: "Finalizar Conversación con el Cliente",
					dialogRatingCancel: "Con despedida",
					dialogRatingSuccess: "Con Calificación",
					dialogRatingWithoutFarewellMsg: "Sin despedida",
					ratingTitle: "Elija un menú de calificación",
          buttons: {
            return: "Devolver",
            resolve: "Finalizar",
            reopen: "Reabrir",
            accept: "Aceptar",
            transfer: "Transferir",
            delete: "Eliminar conversación",
            schedule: "Agendar un mensaje",
			rating: "Enviar calificación",
          },
        },
          vCard: {
            message: "Mensaje",
            send: "Llamar",
            copy:"Copiar",
          },
          location: {
            link: "Copiar Link",
            copy:"Copiar Posición",
          },          
        metaPolicy: " Tienes 24 horas para responder después de recibir un mensaje, según las políticas de Facebook.",
        msgCallDisabled: "Llamada perdida a las",
        greetNewContact: "Saluda a tu nuevo contacto!",
        messageFromMe: "Tú",
        messageDeleted: "🚫 Este mensaje ha sido eliminado por el contacto.",
        messageDeletedFromMe: "🚫 Mensaje suprimido.",
      },
      messagesInput: {
        placeholderOpen: "Escribe un mensaje aquí",
        placeholderClosed:"Haz clic en reabrir o aceptar.",
        signMessage: "Firmar",
      },
      contactDrawer: {
        header: "Detalles del contacto",
        contact: "Contacto",
        copiedText:" ✓ Copiado!",
        tagsContainer: {
            title: "Etiquetas",
        },
        confirmationModal: {
          title: "Eliminar nota",
          saved: "Nota guardada exitosamente!",
          deletConfirm: "¿Estás seguro que deseas eliminar esta nota?",
          deleted: "Nota eliminada!",

        },
        appointmentsModal: {
          title: "Notas",
          textarea: "Agregar nota",
          placeholder: "Escribir",
          deleteConfirm: "Deseja realmente excluir este registro?",
        },
        buttons: {
          delete: "Eliminar",
          save: "Guardar",
          cancel: "Cancelar",
        },
        extraInfo: "Información adicional",
        email: "Correo Electrónico",
      },
      
      ticketListItem: {
        virtualAssistant: "Asistente Virtual",
        myNameIs: "Le saluda",
        assistant: "Estoy aquí para ayudarle en todo lo que esté a mi alcance.",
        noQueue: "Sin área",
        noConection: "No asignada!",
        greeting: {
        morning: "Buenos días",
        afternoon: "Buenas tardes",
        night: "Buenas noches",
        },
        
        bottomButton: {
        pending: "En espera",
        closed: "Finalizado",
        },    
      },      
      ticketOptionsMenu: {
        delete: " Eliminar",
        transfer: " Transferir",
        schedule: " Agendamiento",
        registerAppointment: "Notas de contacto",
        resolveWithNoFarewell: "Final sin despedida",
        acceptAudioMessage: "Aceptar audio del contacto",
        confirmationModal: {
          title: "Vas a eliminar  todos los mensajes de",
//          titleFrom: "del contacto ",
          message: "¿Estás seguro? No se podrán recuperar los mensajes eliminados.",
        },
        toasts: {
          deleted: "Mensajes eliminados permanentemente!",
        },
        buttons: {
          delete: "Eliminar",
          cancel: "Cancelar",
        },
      },
      confirmationModal: {
        buttons: {
          confirm: "Eliminar",
          cancel: "Cancelar",
        },
      },
      messageOptionsMenu: {
        delete: "Eliminar",
        reply: "Responder",
        forward: "Reenviar",
        toForward: "Reenviar",
        talkTo: "Conversar con",
        confirmationModal: {
          title: "¿Eliminar mensaje?",
          message: "Esta acción no puede ser revertida.",
        },
      },
      backendErrors: {
        ERR_NO_OTHER_WHATSAPP: "Debe haber al menos una conexión de WhatsApp predeterminada.",
        ERR_NO_DEF_WAPP_FOUND: "No se encontró WhatsApp predeterminado. Verifique la página de conexiones.",
        ERR_WAPP_NOT_INITIALIZED: "Esta sesión de WhatsApp no ​​está inicializada. Verifique la página de conexiones.",
        ERR_WAPP_CHECK_CONTACT: "No se pudo verificar el contacto de WhatsApp. Verifique la página de conexiones.",
        ERR_WAPP_INVALID_CONTACT: "Este no es un número de whatsapp válido.",
        ERR_WAPP_DOWNLOAD_MEDIA: "No se pudieron descargar los medios de WhatsApp. Verifique la página de conexiones.",
        ERR_INVALID_CREDENTIALS: "Error de autenticación. Vuelva a intentarlo.",
        ERR_SENDING_WAPP_MSG: "Error al enviar el mensaje de WhatsApp. Verifique la página de conexiones.",
        ERR_DELETE_WAPP_MSG: "No se pudo eliminar el mensaje de WhatsApp.",
        ERR_OTHER_OPEN_TICKET: "Ya hay una conversación abierta para este contacto.",
        ERR_SESSION_EXPIRED: "Sesión caducada. Inicie sesión.",
        ERR_USER_CREATION_DISABLED: "La creación de usuarios fue deshabilitada por el administrador.",
        ERR_NO_PERMISSION: "No tienes permiso para acceder a este recurso.",
        ERR_DUPLICATED_CONTACT: "Ya existe un contacto con este número.",
        ERR_NO_SETTING_FOUND: "No se encontró ninguna configuración con este ID.",
        ERR_NO_CONTACT_FOUND: "No se encontró ningún contacto con este ID.",
        ERR_NO_TICKET_FOUND: "No se encontraron conversación con esta ID.",
        ERR_NO_USER_FOUND: "No se encontró ningún usuario con este ID.",
        ERR_NO_WAPP_FOUND: "No se encontró WhatsApp con este ID.",
        ERR_CREATING_MESSAGE: "Error al crear el mensaje en la base de datos.",
        ERR_CREATING_TICKET: "Error al iniciar el chat en la base de datos.",
        ERR_FETCH_WAPP_MSG: "Error al obtener el mensaje en WhtasApp, tal vez sea demasiado antiguo.",
        ERR_QUEUE_COLOR_ALREADY_EXISTS: "Este color ya está en uso, elija otro.",
        ERR_WAPP_GREETING_REQUIRED: "El mensaje de saludo es obligatorio cuando hay más de una fila.",
        ERR_OUT_OF_HOURS: "¡Fuera del horario de oficina!",
        ERR_WAPP_TOKEN_IS_USED: "Este token de WhatsApp ya está en uso.",
        ERR_WAPP_NAME_IS_USED: "Este nombre de conexión ya está en uso.",
        ERR_WAPP_CONNECTIONS_REACHED: "Número máximo de conexiones alcanzado",
        ERR_QUEUE_INVALID_COLOR: "Debe seleccionar un color para este área",
      },
    },
  },
};

export { messages };