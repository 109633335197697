import React, { useEffect, useState } from "react";
import { Box, Chip, TextField } from "@material-ui/core";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import PersonIcon from "@material-ui/icons/Person";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
    
  textField: {
    marginLeft: -3,
    marginTop: -1,
    marginRight: -2,
    marginBottom: -1,
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderWidth: 0,
      },
      '&:hover fieldset': {
        borderWidth: 0,
      },
      '& fieldset': {
        borderWidth: 0,
      },
      '&.Mui-focused:not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
        borderWidth: 0,
      },
      '&:hover:not(.Mui-disabled):not(.Mui-focused) .MuiOutlinedInput-notchedOutline': {
        borderWidth: 0,
      },
    },
  },
  
  textFieldWrapper: {
    marginLeft: "-2px",
    marginRight: "0",
    width: "calc(100% + 5px)",
    flex: 1,
    display: "flex",
    border: `1px solid ${theme.palette.inputSearchBorder}`,
    borderRadius: 4,
    padding: 0,
  },
  personOutlineIcon: {
    color: "grey",
    marginLeft: 7,
    marginRight: 0,
    alignSelf: "center",
  },
  customPaperComponent: {
    backgroundColor: theme.palette.optionsBackground,
    color: theme.palette.paperComponent,
    fontSize: "14px",
//    border: `1px solid ${theme.palette.inputSearchBorder}`,
    boxShadow: `0 1px 1px ${theme.palette.boxShadowColor}`,
    borderRadius: 4,
    marginLeft: "-29px",
    marginRight: "1px",
//    paddingLeft: "10px",
//    paddingRight: "10px",
  },
}));

export function UsersFilter({ onFiltered, initialUsers }) {
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [selecteds, setSelecteds] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      await loadUsers();
    };
    fetchData();
  }, []);

  useEffect(() => {
    setSelecteds([]);
    if (
      Array.isArray(initialUsers) &&
      Array.isArray(users) &&
      users.length > 0
    ) {
      onChange(initialUsers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialUsers, users]);

  const loadUsers = async () => {
    try {
      const { data } = await api.get("/users/list");
      const userList = data.map((u) => ({ id: u.id, name: u.name, color: u.color }));
      setUsers(userList);
    } catch (err) {
      toastError(err);
    }
  };

  const onChange = async (value) => {
    setSelecteds(value);
    onFiltered(value);
  };

  return (
    <Box style={{ padding: "0px 10px 10px" }}>
      <Autocomplete
        multiple
        size="small"
        options={users}
        value={selecteds}
        onChange={(e, v, r) => onChange(v)}
        getOptionLabel={(option) => option.name}
    renderOption={(option, { selected }) => (
<React.Fragment>
  <PersonIcon
    style={{
      color: "#CCCCCC",
      fontSize: "18px",
    }}
  />
  &nbsp;
  <span style={{ fontSize: "14px" }}>{option.name}</span>
</React.Fragment>
    )}        
        renderTags={(value, getUserProps) =>
          value.map((option, index) => (
            <Chip
              key={option.id}
              variant="outlined"
              style={{
                backgroundColor: "#bfbfbf",
                textShadow: "0px 1px 1px rgba(0, 0, 0, 0.2)",
                border: "0px solid rgba(0, 0, 0, 0.2)",
                color: "white",
              }}
              label={option.name}
              {...getUserProps({ index })}
              size="small"
            />
          ))
        }
        renderInput={(params) => (
          <div className={classes.textFieldWrapper}>
            <PersonOutlineIcon className={classes.personOutlineIcon} />
            <TextField
              className={classes.textField}
              {...params}
              variant="outlined"
              placeholder={i18n.t("filters.users.placeholder")}
            />
          </div>
        )}
        PaperComponent={({ children }) => (
          <div className={classes.customPaperComponent}>
            {children}
          </div>
        )}
      />
    </Box>
  );
}
