import React, { useEffect, useState, useCallback } from "react";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import api from "../../services/api";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import { IconButton } from "@material-ui/core";
import { Formik, Field, FieldArray, Form } from "formik";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import SaveIcon from "@material-ui/icons/Save";
import Select from "@material-ui/core/Select";
import { MenuItem, ListItemText } from '@material-ui/core';
import TextField from "@material-ui/core/TextField";
import toastError from "../../errors/toastError";
import { toast } from "react-toastify";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import CustomToolTip from "../ToolTips";
import ConfirmationModal from "../ConfirmationModal";
import { i18n } from "../../translate/i18n";
import Switch from "@material-ui/core/Switch";
import { FormControlLabel } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  greetingMessage: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },
  Box: {
    cursor: "pointer",
    alignItems: "center",
  },
  textField: {
    marginRight: theme.spacing(1),
    flex: 1,
  },
  selectName: {
    marginRight: theme.spacing(1),
    flex: 1,
    width: '200px',
  },
  selectGreetingMessage: {
    marginRight: theme.spacing(1),
    flex: 1,
    width: '200px',
  },
  placeholder: {
    color: theme.palette.grey[500],
    marginBottom: '-2px'
  },  
}));

function getStepContent(step) {
  return <VerticalLinearStepper chatBotId={step} />;
}

export default function VerticalLinearStepper(props) {
  const initialState = {
    options: [],
  };

  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(-1);
  const [steps, setSteps] = React.useState(initialState);
  const [loading, setLoading] = React.useState(false);
  const [isStepContent, setIsStepContent] = React.useState(true);
  const [isNameEdit, setIsNameEdit] = React.useState(null);
  const [isGreetingMessageEdit, setGreetingMessageEdit] = React.useState(null);
  const [selectedQueue, setSelectedQueue] = React.useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = React.useState(false);
  const [queueOptions, setQueueOptions] = useState([]);
  const [selectedOption, setSelectedOption] = React.useState("");
  const [selectedGreetingMessage, setSelectedGreetingMessage] = React.useState("");

  useEffect(() => {
    const fetchQueueOptions = async () => {
      try {
        const { data } = await api.get("/queue");
        setQueueOptions(data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchQueueOptions();
  }, []);

  const handleSaveBot = async (values) => {
    try {
      if (props.chatBotId) {
        await api.put(`/chatbot/${props.chatBotId}`, values);
      } else {
        await api.post("/chatbot", values);
      }
      toast.success(i18n.t("queueModal.options.saved"));
      const { data } = await api.get(`/chatbot/${props.chatBotId}`);
      
      setSteps(initialState);
      setSteps(data);
      setIsNameEdit(null);
      setGreetingMessageEdit(null);
      setSelectedOption(null);
      setSelectedGreetingMessage(null);
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    setLoading(true);

    const delayDebounceFn = setTimeout(() => {
      const fetchList = async () => {
        try {
          const { data } = await api.get(`/chatbot/${props.chatBotId}`);
          setSteps(data);
          setLoading(false);
        } catch (err) {
          // console.log(err);
        }
      };
      fetchList();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [props.chatBotId]);

  useEffect(() => {
    if (activeStep === isNameEdit) {
      setIsStepContent(false);
    } else {
      setIsStepContent(true);
    }
  }, [isNameEdit, activeStep]);

  const handleCloseConfirmationModal = () => {
    setConfirmModalOpen(false);
    setSelectedQueue(null);
  };

  const handleDeleteQueue = async (queueId) => {
    try {
      await api.delete(`/chatbot/${queueId}`);
      const { data } = await api.get(`/chatbot/${props.chatBotId}`);
      setSteps(initialState);
      setSteps(data);
      setIsNameEdit(null);
      setGreetingMessageEdit(null);
      toast.success(i18n.t("queueModal.options.deleted"));
    } catch (err) {
      toastError(err);
    }
    setSelectedQueue(null);
  };

  useEffect(() => {
    if (isNameEdit !== null && steps.options[isNameEdit]) {
      const editedOption = steps.options[isNameEdit];
      
      const queueOption = queueOptions.find(
        (queue) => queue.id === editedOption.isQueue
      );
      
      const selectedValue = editedOption.type === "queue"
        ? queueOption?.name
        : editedOption.name;
        
      const greetingMessageValue = editedOption.type === "queue"
        ? queueOption?.specification
        : editedOption.greetingMessage;

      setSelectedOption(selectedValue || "");
      setSelectedGreetingMessage(greetingMessageValue || "");
    } else {
      setSelectedOption("");
      setSelectedGreetingMessage("");
    }
  }, [isNameEdit, steps.options, queueOptions]);

  function isValidURL(string) {
    const regex = /^(https?:\/\/)?([a-z\d.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
    return regex.test(string);
  }

const [selectOpen, setSelectOpen] = useState(false);

const getEmbeddingFromOpenAI = useCallback(async (text) => {
  try {
    const response = await api.post("/openai/embedding", { text });
    return response.data.embedding;
  } catch (error) {
    console.error("Error fetching embedding:", error);
    toast.error("Error fetching embedding");
    return null; // o manejar el error como prefieras
  }
}, []);

  return (
    <div className={classes.root}>
      <ConfirmationModal
        title={
          selectedQueue &&
          `${i18n.t("queueModal.options.confirmationModal.deleteTitle")} ${
            selectedQueue.name
          }?`
        }
        open={confirmModalOpen}
        onClose={handleCloseConfirmationModal}
        onConfirm={() => handleDeleteQueue(selectedQueue.id)}
      >
        {i18n.t("queueModal.options.confirmationModal.deleteMessage")}
      </ConfirmationModal>

      {!loading && (
        <div>
          <Formik
            initialValues={steps}
            validateOnChange={false}
            enableReinitialize={true}
            onSubmit={(values, actions) => {
              setTimeout(() => {
                actions.setSubmitting(false);
              }, 400);
            }}
          >
            {({ touched, errors, isSubmitting, values, handleSubmit, setFieldValue }) => (
              <Form>
                <FieldArray name="options">
                  {({ push, remove }) => (
                    <>
                      <Stepper nonLinear activeStep={activeStep} orientation="vertical">
                        {values.options &&
                          values.options.length > 0 &&
                          values.options.map((info, index) => (
                            <Step
                              key={`${info.id ? info.id : index}-options`}
                              onClick={() => setActiveStep(index)}
                            >
                              <StepLabel key={`${info.id}-options`}>
                                {isNameEdit !== index && steps.options[index]?.id ? (
                                  <div className={classes.greetingMessage} variant="body1">
                                    {values.options[index].type === "queue" ? (
                                      queueOptions.find((queue) => queue.id === values.options[index].isQueue)?.name + " (" + i18n.t("queueModal.options.queue") + ")"
                                    ) : (
                                      values.options[index]?.name
                                    )}
                                    <IconButton
                                      size="small"
                                      onClick={() => {
                                        setIsNameEdit(index);
                                        setIsStepContent(false);
                                      }}
                                    >
                                      <EditIcon />
                                    </IconButton>
                                    <IconButton
                                      onClick={() => {
                                        setSelectedQueue(info);
                                        setConfirmModalOpen(true);
                                      }}
                                      size="small"
                                    >
                                      <DeleteOutline />
                                    </IconButton>
                                  </div>
                                ) : (
                                  <>
                                    {values.options[index].type === "queue" ? (
                                        <Field
                                          as={Select}
                                          variant="standard"
                                          color="primary"
                                          displayEmpty
                                          disabled={isSubmitting}
                                          error={
                                            touched?.options?.[index]?.name &&
                                            Boolean(errors.options?.[index]?.name)
                                          }
                                          className={classes.selectName}
                                          value={selectedOption}
                                          onChange={(e) => {
                                            const selectedValue = e.target.value;
                                            setSelectedOption(selectedValue);
                                          }}
                                          onOpen={() => setSelectOpen(true)}
                                          onClose={() => setSelectOpen(false)}
                                        >
                                            {
                                              !selectOpen && (
                                                <MenuItem value="">
                                                  <ListItemText primary={i18n.t("queueModal.options.option")} className={classes.placeholder} />
                                                </MenuItem>
                                              )
                                            }
                                          {queueOptions
                                            .filter((queue, currentIndex) => {
                                              const currentName = queue.name;
                                              return (
                                                currentIndex ===
                                                queueOptions.findIndex(
                                                  (item) => item.name === currentName
                                                )
                                              );
                                            })
                                            .map((queue) => (
                                              <MenuItem key={queue.name} value={queue.name}>
                                                {queue.name}
                                              </MenuItem>
                                            ))}
                                        </Field>
                                    ) : (
                                      <Field
                                        as={TextField}
                                        name={`options[${index}].name`}
                                        variant="standard"
                                        color="primary"
                                        disabled={isSubmitting}
                                        error={
                                          touched?.options?.[index]?.name &&
                                          Boolean(errors.options?.[index]?.name)
                                        }
                                        className={classes.textField}
                                        placeholder={i18n.t("queueModal.options.option")}
                                      />
                                    )}
                                    <Field
                                      as={Select}
                                      variant="standard"
                                      color="primary"
                                      value={values.options[index].type}
                                      onChange={(e) => {
                                        setFieldValue(`options[${index}].type`, e.target.value);
                                      }}
                                    >
                                      <MenuItem value="option">{i18n.t("queueModal.options.option")}</MenuItem>
                                      <MenuItem value="queue">{i18n.t("queueModal.options.queue")}</MenuItem>
                                    </Field>
                                    <IconButton
                                      size="small"
                                      onClick={() => {
                                        if (
                                          (values.options[index].type === "option" && values.options[index].name && values.options[index].greetingMessage) || 
                                          (values.options[index].type === "queue" && values.options[index].isQueue)
                                        ) {
                                          handleSaveBot(values);
                                        } else {
                                          toast.error(i18n.t("queueModal.options.fieldsRequired"));
                                        }
                                      }}
                                      disabled={isSubmitting}
                                    >
                                      <SaveIcon />
                                    </IconButton>
                                    <IconButton
                                      size="small"
                                      onClick={() => {
                                        remove(index)
                                        setSelectedOption("");
                                        setSelectedGreetingMessage("");
                                      }}
                                      disabled={isSubmitting}
                                    >
                                      <DeleteOutline />
                                    </IconButton>
                                    <div className={classes.greetingMessage}>
  {values.options[index].type === "queue" ? (
    <Field
      as={Select}
      variant="standard"
      color="primary"
      displayEmpty
      disabled={isSubmitting}
      error={
        touched?.options?.[index]?.greetingMessage &&
        Boolean(errors?.options?.[index]?.greetingMessage)
      }
      className={classes.selectGreetingMessage}
      value={selectedGreetingMessage}
      onChange={(e) => {
        const selectedValue = e.target.value;
        setSelectedGreetingMessage(selectedValue);
        if (e.target.value) {
          const selectedQueueOption = queueOptions.find(
            (queue) =>
              queue.name === selectedOption &&
              queue.specification === e.target.value
          );
if (selectedQueueOption) {
  setFieldValue(`options[${index}].isQueue`, selectedQueueOption.id);

  getEmbeddingFromOpenAI(selectedQueueOption.name).then((embeddingArray) => {
  console.log("selectedQueueOption:", selectedQueueOption.name);
    if (embeddingArray) {
//    const embeddingArray = [-0.013997165,0.02815072,-0.008555994,0.013058807,-0.014127492,-0.0183501,0.03346808,-0.013971099,0.036231022,-0.005591046,0.0077740303,-0.013527986,0.024358194,-0.01036103,0.0073765316,0.007539441,-0.013280364,0.0031050507,0.030731205,-0.003548164,-0.0042486736,-0.013971099,0.002759683,-0.0061807777,0.0060960646,-0.0033168327,0.020161651,0.010602135,-0.008575544,-0.019718537,-0.0099830795,-0.033859063,-0.007174524,-0.0077740303,-0.013156553,-0.0149094565,0.0023149406,0.0205396,0.0024485262,-0.024553685,-0.010641233,0.007910874,-0.03513627,-0.0077935793,-0.023889014,0.0062003266,0.009038206,0.003479742,-0.053121455,-0.027055971,0.016173633,0.00021972388,-0.012250776,0.009468286,-0.0038935314,-0.0149094565,-0.0047699832,-0.0099830795,-0.016277894,0.016290927,0.000017283655,0.015717486,0.013423723,0.003496033,-0.00021422569,-0.02411057,0.0024517844,0.021973202,0.0019402493,-0.011345001,0.027733674,0.001425456,0.007787063,-0.004665721,0.00594293,-0.005167482,0.0053336495,-0.03870724,-0.0045093284,0.0031180833,0.014883391,0.038629044,0.02425393,0.023889014,-0.031148251,-0.042434607,0.0056855334,0.0033885126,0.010954019,0.010823691,-0.014870358,-0.0014906197,-0.010282833,0.026821382,0.0048449216,-0.01915813,-0.00657176,-0.0009334701,-0.024227867,0.0021504024,0.0027844870];
      const embeddingString = `[${embeddingArray.join(',')}]`;
      setFieldValue(`options[${index}].embedding`, embeddingString);
      console.log("embeddingString:", embeddingArray);
    }
  });
}

        }
      }}
                                          onOpen={() => setSelectOpen(true)}
                                          onClose={() => setSelectOpen(false)}      
    >
                                            {
                                              !selectOpen && (
                                                <MenuItem value="">
                                                  <ListItemText primary={i18n.t("queueModal.options.specification")} className={classes.placeholder} />
                                                </MenuItem>
                                              )
                                            }    
      {queueOptions
        .filter((queue) => queue.name === selectedOption)
        .map((queue) => (
          <MenuItem key={queue.specification} value={queue.specification}>
            {queue.specification}
          </MenuItem>
        ))}
    </Field>
  ) : (
    <Field
      as={TextField}
      name={`options[${index}].greetingMessage`}
      variant="standard"
      margin="dense"
      fullWidth
      multiline
      error={
        touched?.options?.[index]?.greetingMessage &&
        Boolean(errors?.options?.[index]?.greetingMessage)
      }
      helperText={
        touched?.options?.[index]?.greetingMessage &&
        errors?.options?.[index]?.greetingMessage
      }
      className={classes.textField}
      placeholder={i18n.t("queueModal.options.message")}
    />
  )}
</div>

                                  </>
                                )}
                              </StepLabel>
                              {isStepContent && steps.options[index] && (
                                <StepContent>
                                {values.options[index].type === "queue" ? (
                                  <div className={classes.greetingMessage}>
                                <Typography color="textSecondary" variant="body1" style={{ fontSize: '0.7m' }}>
                                  {i18n.t("queueModal.options.specification")}:
                                </Typography>
                                    &nbsp;
                                    {queueOptions.find(
                                      (queue) =>
                                        queue.id ===
                                        values.options[index].isQueue
                                    )?.specification}
                                  </div>
                                ) : (
                                  <div className={classes.greetingMessage}>
                                    <Typography color="textSecondary" variant="body1">
                                      {i18n.t("queueModal.options.message")}:
                                    </Typography>
                                    &nbsp;
                                    {values.options[index].greetingMessage}
                                  </div>
                                )}
                                    {getStepContent(info.id)}
                                
                                </StepContent>

                              )}
                            </Step>
                          ))}
                      </Stepper>
                      {isNameEdit === null && (
                        <Button
                          color="primary"
                          size="small"
                          onClick={() =>
                            push({
                              type: "option",
                            })
                          }
                          style={{
                            width: 24,
                            height: 24,
                            minWidth: 0,
                            padding: 0,
                            marginLeft: 23,
                            marginTop: 10,
                            borderRadius: "50%",
                            borderWidth: 3,
                          }}
                          variant="outlined"
                        >
                          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <AddIcon style={{ fontSize: 16 }} />
                          </div>
                        </Button>
                      )}  
                    </>
                  )}
                </FieldArray>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </div>
  );
}
