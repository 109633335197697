import React from "react";
import { Card, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Hidden } from "@material-ui/core";
import TicketHeaderSkeleton from "../TicketHeaderSkeleton";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  ticketHeader: {
    height: "72px",
    display: "flex",
    background: theme.palette.tabHeaderBackground,
    flex: "none",
    borderLeft: 0,
    borderTop: 0,
    borderRight: 0,
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
  },
  backButton: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    minWidth: "40px",
    padding: theme.spacing(0, 0.5),
  },
}));

const TicketHeader = React.memo(({ loading, children }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleBack = () => {
    history.push("/");
  };

  return (
    <>
      {loading ? (
        <TicketHeaderSkeleton />
      ) : (
        <Card elevation={0} square variant="outlined" className={classes.ticketHeader}>
          <Hidden only={["sm", "xs"]}>
            <Button color="primary" onClick={handleBack} className={classes.backButton}>
              <ArrowBackIos />
            </Button>
          </Hidden>
          {children}
        </Card>
      )}
    </>
  );
});

export default TicketHeader;
