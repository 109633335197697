import React, { useState, useRef, useEffect } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Checkbox, ListItemText, makeStyles } from "@material-ui/core";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
  formControl: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.inputSearchBorder,
      borderWidth: "1px",
      borderStyle: "solid",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.inputSearchBorder,
      borderWidth: "1px",
      borderStyle: "solid",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.inputSearchBorder,
      borderWidth: "1px",
      borderStyle: "solid",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      backgroundColor: "transparent",
    },
    "& .MuiOutlinedInput-root .MuiSelect-select.MuiSelect-select": {
      color: "#B3B3B3",
    },  
  },
  
  menuPaper: {
        overflowY: "scroll",
    ...theme.scrollbarStyles,  
  },
  
  inputSelectWrapper: {
    width: 120,
    marginTop: -4,
  },
  
  selectedMenuItem: {
    backgroundColor: theme.palette.action.selected,
  },  
  
}));

const TicketsQueueSelect = ({ userQueues, selectedQueueIds = [], onChange }) => {
  const classes = useStyles();

  const handleChange = (event) => {
    onChange(event.target.value);
  };

  const selectRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsFocused(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

const handleToggleAll = () => {
  const allQueueIds = userQueues.map((queue) => queue.id);
  setTimeout(() => {
    onChange(selectedQueueIds.length === userQueues.length ? [] : allQueueIds);
  }, 0);
};

  const handleLabelClick = () => {
    handleToggleAll();
  };

  return (
    <div className={classes.inputSelectWrapper}>
      <FormControl
        fullWidth
        margin="dense"
        className={`${classes.formControl} ${isFocused ? "focused" : ""}`}
        ref={selectRef}
      >
        <Select
          multiple
          displayEmpty
          variant="outlined"
          value={selectedQueueIds}
          onChange={handleChange}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
            PaperProps: {
              className: classes.menuPaper
            }    
          }}
          renderValue={() => i18n.t("ticketsQueueSelect.placeholder")}
        >
          <MenuItem
            dense
            onClick={handleLabelClick}
            className={selectedQueueIds.length === userQueues.length ? classes.selectedMenuItem : ""}
          >
            <Checkbox
              style={{
                color: "#000000",
              }}
              size="small"
              color="primary"
              indeterminate={selectedQueueIds.length > 0 && selectedQueueIds.length < userQueues.length}
              checked={selectedQueueIds.length === userQueues.length}
              onChange={handleToggleAll}
            />
            <ListItemText primary={i18n.t("ticketsQueueSelect.all")} />
          </MenuItem>
          {userQueues?.map((queue) => (
            <MenuItem dense key={queue.id} value={queue.id}>
              <Checkbox
                style={{
                  color: queue.color,
                }}
                size="small"
                color="primary"
                checked={selectedQueueIds.includes(queue.id)}
              />
              <ListItemText primary={`${queue.name} - ${queue.specification}`} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default TicketsQueueSelect;