import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Card, CardHeader, Hidden } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";

const useStyles = makeStyles((theme) => ({
  ticketHeader: {
    display: "flex",
    background: theme.palette.background.default,
    flex: "none",
    borderLeft: 0,
    borderTop: 0,
    borderRight: 0,
    height: "72px",
    alignItems: "center",
    padding: "0 16px",
  },
  avatarContainer: {
    display: "flex",
    alignItems: "center",
  },
  arrowBack: {
    marginLeft: theme.spacing(-2.5),
    marginRight: theme.spacing(0.5),
    color: "#757575",
  },
}));

const TicketHeaderSkeleton = () => {
  const classes = useStyles();

  return (
    <Card square variant="outlined" className={classes.ticketHeader}>
      <CardHeader
        titleTypographyProps={{ noWrap: true }}
        subheaderTypographyProps={{ noWrap: true }}
        avatar={
          <div className={classes.avatarContainer}>
            <Hidden only={["sm", "xs"]}>
              <ArrowBackIos className={classes.arrowBack} />
            </Hidden>
            <Skeleton
              animation="wave"
              variant="circle"
              width={46}
              height={46}
              style={{
                outline: `1px solid #757575`,
                outlineOffset: "3px",
                borderRadius: "50%",
              }}
            />
          </div>
        }
        title={<Skeleton animation="wave" width={80} />}
        subheader={<Skeleton animation="wave" width={140} />}
      />
    </Card>
  );
};

export default TicketHeaderSkeleton;
