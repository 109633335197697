import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import ContactNotesDialogListItem from '../ContactNotesDialogListItem';
import { toast } from "react-toastify";
import { i18n } from "../../translate/i18n";
import ButtonWithSpinner from '../ButtonWithSpinner';
import useTicketNotes from '../../hooks/useTicketNotes';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      width: '350px',
    },
  },
  list: {
    width: '100%',
    maxWidth: '350px',
    backgroundColor: theme.palette.background.paper,
  },
  noteInput: {
    marginTop: -8,
    marginBottom: -8,
    backgroundColor: theme.palette.background.paper,
    '& label.Mui-focused': {
      color: "rgba(0, 0, 0, 0.12)",
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: "rgba(0, 0, 0, 0.12)",
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: "rgba(0, 0, 0, 0.12)",
        borderWidth: 0,
      },
      '&:hover fieldset': {
        borderColor: "rgba(0, 0, 0, 0.12)",
        borderWidth: 0,
      },
      '& fieldset': {
        borderColor: "rgba(0, 0, 0, 0.12)",
        borderWidth: 0,
      },
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginLeft: 'auto',
  },
  button: {
    marginLeft: theme.spacing(1),
  },
}));

const NoteSchema = Yup.object().shape({
  note: Yup.string()
    .min(2, i18n.t("contactDrawer.validation.extend"))
    .required(i18n.t("contactDrawer.validation.required"))
});

export function ContactNotes({ ticket }) {
  const { id: ticketId, contactId } = ticket;
  const classes = useStyles();
  const [placeholderText, setPlaceholderText] = useState(i18n.t("contactDrawer.appointmentsModal.textarea"));
  const [newNote, setNewNote] = useState({ note: "" });
  const [loading, setLoading] = useState(false);
  const [notes, setNotes] = useState([]);
  const { saveNote, deleteNote, listNotes } = useTicketNotes();

  useEffect(() => {
    async function fetchData() {
      await loadNotes();
    }
    fetchData();
  }, []);

  const handleChangeComment = (e) => {
    setNewNote({ note: e.target.value });
  };

  const handleSave = async values => {
    setLoading(true);
    try {
      await saveNote({
        ...values,
        ticketId,
        contactId
      });
      await loadNotes();
      setNewNote({ note: '' });
    } catch (e) {
      toast.error(e);
    }
    setLoading(false);
  };

  const handleDelete = async noteId => {
    setLoading(true);
    try {
      await deleteNote(noteId);
      await loadNotes();
    } catch (e) {
      toast.error(e);
    }
    setLoading(false);
  };

  const loadNotes = async () => {
    setLoading(true);
    try {
      const notes = await listNotes({ ticketId, contactId });
      setNotes(notes);
    } catch (e) {
      toast.error(e);
    }
    setLoading(false);
  };

  const renderNoteList = () => {
    return notes.map((note) => (
      <ContactNotesDialogListItem
        note={note}
        key={note.id}
        deleteItem={() => handleDelete(note.id)}
      />
    ));
  };

  return (
    <Formik
      initialValues={newNote}
      enableReinitialize={true}
      validationSchema={NoteSchema}
      onSubmit={(values, actions) => {
        setTimeout(() => {
          handleSave(values);
          actions.setSubmitting(false);
        }, 400);
      }}
    >
      {({ touched, errors, setErrors, values, setFieldValue }) => (
        <Form>
          <Grid container spacing={2}>
            {notes.length > 0 && (
              <Grid xs={12} item>
                <List className={classes.list}>
                  {renderNoteList()}
                </List>
              </Grid>
            )}
            <Grid xs={12} item>
              <Field
                as={TextField}
                name="note"
                maxRows={10}
                className={classes.noteInput}
                placeholder={placeholderText}
                onFocus={() => setPlaceholderText(i18n.t("contactDrawer.appointmentsModal.placeholder"))}
                onBlur={() => setPlaceholderText(i18n.t("contactDrawer.appointmentsModal.textarea"))}
                multiline={true}
                error={touched.note && Boolean(errors.note)}
                helperText={touched.note && errors.note}
                variant="outlined"
                onChange={handleChangeComment}
                fullWidth
              />
            </Grid>
            {values.note && (
              <Grid xs={12} item className={classes.buttonContainer}>
                <Button
                  onClick={() => {
                    setNewNote("");
                    setErrors({});
                  }}
                  color="secondary"
                  variant="outlined"
                  className={classes.button}
                  size="small"
                >
                  {i18n.t("contactDrawer.buttons.cancel")}
                </Button>
                <ButtonWithSpinner
                  loading={loading}
                  color="primary"
                  type="submit"
                  variant="contained"
                  className={classes.button}
                  size="small"
                >
                  {i18n.t("contactDrawer.buttons.save")}
                </ButtonWithSpinner>
              </Grid>
            )}
          </Grid>
        </Form>
      )}
    </Formik>
  );
}
